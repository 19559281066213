let wConfiguratorCustomization = (function() {
    let template = `
        <section class="configurator_customization">
            <div v-if="errors.length">
                <h4>{{ 'customization_error' | translate }}</h4>

                <ul>
                    <li v-for="error in errors">{{ error }}</li>
                </ul>

                <p><strong>{{ 'please_contact_support' | translate }}</strong></p>
            </div>
            <div v-else class="steps" :key="key" v-for="(mystep, key) in steps">
                <div class="content">
                    <div class="customization_container" v-show="configuratorStore.getStep()==(mystep.position)">
                        <w-configurator-customization-add-product
                            v-if="product.configuration.option_ecusson"
                            :configurator="configurator"
                            :step="mystep"
                            :selectedCustomizationStep="selectedCustomizationStep[mystep.id]!=undefined ? selectedCustomizationStep[mystep.id]: ''"
                            :customizationSteps="customizationSteps"
                            :array_ecusson_size_configurator="array_ecusson_size_configurator"
                            ref="customizationProduct"
                        ></w-configurator-customization-add-product>

                        <w-configurator-customization-add-text
                            v-if="product.configuration.option_broderie"
                            :configurator="configurator"
                            :step="mystep"
                            :selectedCustomizationStep="selectedCustomizationStep[mystep.id]!=undefined ? selectedCustomizationStep[mystep.id]: ''"
                            :customizationSteps="customizationSteps"
                            @addText="addText"
                            ref="customizationText"
                        ></w-configurator-customization-add-text>
                    </div>
                </div>
            </div>
        </section>
    `;

    return {
        name: 'wConfiguratorCustomization',
        template: template,
        components: {
            wConfiguratorCustomizationAddProduct,
            wConfiguratorCustomizationAddText
        },
        props: {
            originalProduct: {type: Object},
            array_ecusson_size_configurator: {type: Object}
        },
        filters: {
            translate: Translator.translate,
        },
        data() {
            return {
                product: {},
                steps: {},
                customizationSteps:[],
                configuratorStore: configuratorStore,
                errors: [],
                selectedCustomizationStep: {}
            }
        },
        watch: {
            originalProduct: function() {
                this.initCustomization();
            }
        },
        mounted() {
            this.initCustomization();
        },
        methods: {
            initCustomization: function() {
                this.$emit('updateLoader',true);
                this.product = this.originalProduct;
                this.getCustomization();
            },

            getCustomization: function() {
                this.$emit('updateLoader',true);
                $.ajax({
                    url: path_relative_root + "ajax_configurator_bystep.php",
                    type: "post",
                    dataType: 'json',
                    data: {
                        product_id: this.product.id
                    },
                    success: this.onSearchProdEnd
                });
            },

            // Une fois la recherche effectué on rempli les données
            onSearchProdEnd: function onSearchProdEnd(res) {

                // Vérification que toutes les configurations sont opérationnel
                if (this.checkConfigurator(res)) {
                    // On enregistre la structure des différents configurator
                    let product_config = this.product.configurator;

                    this.product = res.product;
                    // L'id dans la liste des produits (produit principal toujours id 0)
                    this.product.id_list = 0;
                    this.product.type = $('#product_type').val();

                    // Les différentes configuration
                    if (res.configurator) {
                        this.configurator = res.configurator;
                    }

                    // Si le produit n'a pas déjà de configuration (cas edition configuration)
                    if (res.product.configurator === undefined) {
                        // Init de la stucture de configurator
                        this.product.configurator = product_config;
                    }

                    // Les différentes étapes
                    this.steps = res.steps;
                    this.configuratorStore.state.maxStep += Object.keys(this.steps).length;

                    this.updateStepsPositions();

                    //updatecustomizationSteps
                    let _this = this;
                    $.each(_this.steps, function(stepIndex,step) {
                        if (_this.product.configuration.option_ecusson) {
                            _this.selectedCustomizationStep[step.id] = 'addProduct';
                        }
                        else if(_this.product.configuration.option_broderie) {
                            _this.selectedCustomizationStep[step.id] = 'addText';
                        }
                        else {
                            _this.selectedCustomizationStep[step.id] = '';
                        }
                    });

                    this.initCustomizationSteps();

                    this.configuratorStore.setCustomizedProduct(this.product);

                    this.$emit('setConfigurator',this.configurator);
                }
                this.$emit('updateLoader', false);
            },

            initCustomizationSteps: function() {
                if (this.product.configuration.option_ecusson) {
                    this.customizationSteps.push('addProduct');
                }
                if(this.product.configuration.option_broderie) {
                    this.customizationSteps.push('addText');
                }
            },

            updateStepsPositions: function() {
                let _this = this;
                $.each(_this.steps, function(indexStep, step) {
                    var position = parseInt(step.position);
                    position++;
                    _this.steps[indexStep].position = position;
                });
            },

            // Permet de savoir si toutes les configurations sont bien chargé
            checkConfigurator: function checkConfigurator(res) {
                let _this = this;
                // Cas où il n'y a pas de produit gravure (broderie)
                if (res.configurator.option_broderie === undefined || res.configurator.option_broderie.products.id === undefined) {
                    _this.errors.push(_this.$options.filters.translate('writing_product_not_configured'));
                }

                if ($('#product_type').val() === '') {
                    _this.errors.push(_this.$options.filters.translate('Produit modèle vide'));
                }

                if (res.error_message !== undefined) {
                    $.each(res.error_message, function(index, error) {
                        _this.errors.push(_this.$options.filters.translate(error));
                    });
                }
                if (_this.errors.length) {
                    return false;
                }
                return true;
            },

            addText: function() {
                this.$emit('addText');
            },

            selectCustomizationStep: function(stepId, customizationStep) {
                this.selectedCustomizationStep[stepId] = customizationStep;
                let key = 0;
                $.each(this.steps,function (indexStep, step) {
                    if(step.id==stepId) {
                        key = (indexStep-1);
                    }
                });
                this.$refs.customizationProduct[key].setSelectedCustomizationStep(customizationStep);
                this.$refs.customizationText[key].setSelectedCustomizationStep(customizationStep);
            }
        }

    }

}());
