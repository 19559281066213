let wConfiguratorPreviewProduct = (function() {
    let template = `
        <section class="configurator_preview">
            <div class="swiper-container">
                <ul class="swiper-wrapper">
                    <li class="swiper-slide" :data-slideIndex="pictureKey" v-for="picture, pictureKey in pictures">
                        <img
                            :src="picture.alternatePicture"
                            alt="D"
                            v-if="selectedProduct!=undefined && selectedProduct.id==picture.product.id && picture.letter=='Z' && picture.alternatePicture!=undefined && configuratorStore.state.selectedFilters.zip!=undefined && (configuratorStore.state.selectedFilters.zip=='oui' || configuratorStore.state.selectedFilters.zip=='yes')"
                        />
                        <img
                            :src="picture.src"
                            :alt="picture.letter"
                            :id="'picture_'+picture.product.id+'_'+picture.letter"
                            v-else
                        />
                    </li>
                </ul>
            </div>
            <div class="swiper-scrollbar-container">
                <div class="swiper-scrollbar"></div>
            </div>
        </section>
    `;

    return {
        name: 'wConfiguratorPreviewProduct',
        template: template,
        components: {
        },
        props: {
            product: {type: Object}
        },
        filters: {
            translate: Translator.translate,
        },
        data() {
            return {
                pictures: [],
                swiper: {},
                configuratorStore: configuratorStore,
                selectedProduct: {}
            }
        },
        watch: {
            "configuratorStore.state.searchStep": function(value) {
                if (value==2) {
                    this.initFilteredProductsPictures(true);

                }
                else {
                    this.init();
                }
            },
            "product.id": function() {
                if (this.configuratorStore.state.searchStep==2) {
                    this.initFilteredProductsPictures();
                }
                else {
                    this.init();
                }
            },
            "configuratorStore.state.selectedFilters.taille": function(value) {
                let pictureIndex = false;
                let _this = this;
                $.each(_this.pictures, function (pictureKey, picture) {
                    if (picture.size != undefined && $.trim(picture.size) == $.trim(value)) {
                        pictureIndex = pictureKey;
                        _this.selectedProduct = picture.product;
                    }
                });
                if (_this.swiper != undefined && pictureIndex !== false) {
                    _this.swiper.slideTo(pictureIndex);
                }
            },
            "configuratorStore.state.selectedFilters.size": function(value) {
                let pictureIndex = false;
                let _this = this;
                $.each(_this.pictures, function (pictureKey, picture) {
                    if (picture.size != undefined && $.trim(picture.size) == $.trim(value)) {
                        pictureIndex = pictureKey;
                        _this.selectedProduct = picture.product;
                    }
                });
                if (_this.swiper != undefined && pictureIndex !== false) {
                    _this.swiper.slideTo(pictureIndex);
                }
            }
        },
        mounted() {

        },
        methods: {
            init: function() {
                let _this = this;
                _this.pictures = [];

                if (_this.product === undefined) {
                    return;
                }

                $.each(_this.product.pictures, function(letter, picture) {
                    _this.pictures.push({
                        letter: letter,
                        src: picture,
                        product: _this.product
                    });
                });

                setTimeout(_this.initSwiper,30);
            },

            initFilteredProductsPictures: function(initSwiper) {
                let _this = this;
                let filteredProducts = _this.configuratorStore.state.filteredProducts;
                let arrayPictures = [];
                $.each(filteredProducts, function (productKey, product) {
                    let productSize = '';
                    $.each(product.characteristics, function (characteristicIndex, characteristic) {
                        if (characteristic.label_noaccent == 'taille' || characteristic.label_noaccent == 'size') {
                            productSize = characteristic.value;
                        }
                    });

                    if (productSize) {
                        let exists = arrayPictures.find(function (productPicture) {
                            return $.trim(productPicture.size) == $.trim(productSize);
                        });

                        if (exists == undefined) {
                            arrayPictures.push({
                                size: productSize,
                                pictures: product.pictures,
                                product: product
                            });
                        }
                    }
                });

                let arraySortPictures = [];
                $.each(this.configuratorStore.sizesOrder[this.configuratorStore.state.langId], function(index, sizeName){
                   $.each(arrayPictures, function (indexPicture, product){
                      if (product.size == sizeName) {
                          arraySortPictures.push(product);
                      }
                   });
                });

                _this.pictures = [];
                $.each(arraySortPictures, function (sizeKey, size) {
                    $.each(size.pictures, function (letter, picture) {
                        if (letter=='Z') {
                            _this.pictures.push({
                                letter: letter,
                                src: picture,
                                product: size.product,
                                size: size.size,
                                alternatePicture: size.product.pictures['D']
                            });
                        }
                    });
                });

                if(initSwiper) {
                    setTimeout(_this.initSwiper,30);
                }
            },

            initSwiper: function() {
                setTimeout(function() {
                    this.swiper = new Swiper($('.configurator_preview .swiper-container'), {
                        slidesPerView: 2,
                        centeredSlides: true,
                        scrollbar: {
                            el: '.swiper-scrollbar',
                            draggable: true,
                            hide: false
                        },
                    });

                    this.configuratorStore.previewSwiper = this.swiper;
                }, 500);
            },

            compareSize: function(a,b) {
                // Use toUpperCase() to ignore character casing
                const nameA = a.size.toUpperCase();
                const nameB = b.size.toUpperCase();

                let comparison = 0;
                if (nameA > nameB) {
                    comparison = 1;
                } else if (nameA < nameB) {
                    comparison = -1;
                }
                return comparison;
            }
        }
    }

}());
