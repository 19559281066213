let wConfiguratorCustomizationAddProduct = (function() {
    let template = `
        <section class="configurator_customization_add_product">
            <div class="content">
                <div class="products_container">
                    <template v-if="isMobile">
                        <div class="titles_container">
                            <div class="product_container title_container" v-for="products, productType, key in moveKeyFirst(this.configurator.option_ecusson.products, 'new in')">
                                <div v-if="productType=='collab'" :data-range="productType" class="customization_title collab" :class="{active: (key==0)}" @click="handleActiveCustomization">
                                    <span>{{ 'collab_title' | translate }}</span>
                                    <img :src="pathRelativeRoot+'img/logo_collab.png'" />
                                    <span class="custom_count">{{'(' + products.length + ')'}}</span>
                                </div>
                                <div v-else class="customization_title" :data-range="productType" :class="{active: (key==0)}" @click="handleActiveCustomization">
                                    <span>{{ productType | translate }}</span>
                                    <span class="custom_count">{{'(' + products.length + ')'}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="contents_container">
                            <div class="product_container content_container" v-for="products, productType, key in moveKeyFirst(this.configurator.option_ecusson.products, 'new in')">
                                <div class="product_ranges customize_content swiper-container" :data-range="productType" :class="['products_' + productType, {active: (key==0)}]">
                                    <div class="swiper-btn swiper-button-prev"></div>
                                    <ul class="swiper-wrapper">
                                        <li v-for="product in products" class="swiper-slide">
                                            <div
                                                :id="'item_'+step.id+'_'+product.id"
                                                class="addProducts"
                                                :step_id="step.id"
                                                :product_id="product.id"
                                                :color_id="product.color_id"
                                                :size_id="product.size_id"
                                                :style="'width:'+(array_ecusson_size_configurator[productType]?.width ?? product.width)+'px;height:'+(array_ecusson_size_configurator[productType]?.height ?? product.height)+'px'"
                                                :data-width="array_ecusson_size_configurator[productType]?.width ??  product.width"
                                                :data-height="array_ecusson_size_configurator[productType]?.height ?? product.height"
                                            >
                                                <img
                                                    :src="product.picture"
                                                    :alt="product.title"
                                                />
                                            </div>

                                        </li>
                                    </ul>
                                    <div class="swiper-btn swiper-button-next"></div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <div class="product_container" v-for="products, productType, key in moveKeyFirst(this.configurator.option_ecusson.products, 'new in')">
                            <div v-if="productType=='collab'" :data-range="productType" class="customization_title collab" :class="{active: (key==0)}" @click="handleActiveCustomization">
                                <span>{{ 'collab_title' | translate }}</span>
                                <img :src="pathRelativeRoot+'img/logo_collab.png'" />
                                <span class="custom_count">{{'(' + products.length + ')'}}</span>
                            </div>
                            <div v-else class="customization_title" :data-range="productType" :class="{active: (key==0)}" @click="handleActiveCustomization">
                                <span>{{ productType | translate }}</span>
                                <span class="custom_count">{{'(' + products.length + ')'}}</span>
                            </div>

                            <div class="product_ranges customize_content swiper-container" :data-range="productType" :class="['products_' + productType, {active: (key==0)}]">
                                <div class="swiper-btn swiper-button-prev"></div>
                                <ul class="swiper-wrapper">
                                    <li v-for="product in products" class="swiper-slide">
                                        <div
                                            :id="'item_'+step.id+'_'+product.id"
                                            class="addProducts"
                                            :step_id="step.id"
                                            :product_id="product.id"
                                            :color_id="product.color_id"
                                            :size_id="product.size_id"
                                            :style="'width:'+(array_ecusson_size_configurator[productType]?.width ?? product.width)+'px;height:'+(array_ecusson_size_configurator[productType]?.height ?? product.height)+'px'"
                                            :data-width="array_ecusson_size_configurator[productType]?.width ??  product.width"
                                            :data-height="array_ecusson_size_configurator[productType]?.height ?? product.height"
                                        >
                                            <img
                                                :src="product.picture"
                                                :alt="product.title"
                                            />
                                        </div>

                                    </li>
                                </ul>
                                <div class="swiper-btn swiper-button-next"></div>
                            </div>
                        </div>
                    </template>
                </div>

                <button class="validate_buttons"  @click="validateStep(step.id)">{{ 'validate' | translate}} </button>
            </div>
        </section>
    `;

    return {
        name: 'wConfiguratorCustomizationAddProduct',
        template: template,
        components: {
            wConfiguratorVisual
        },
        props: {
            configurator: {type: Object, default: null},
            step: {type: Object},
            selectedCustomizationStep: {type: String, default: ''},
            customizationSteps: {type: Array, default: []},
            array_ecusson_size_configurator: {type: Object}
        },
        filters: {
            translate: Translator.translate,
        },
        data() {
            return {
                products: {},
                getSelectedCustomizationStep: '',
                configuratorStore: configuratorStore,
                pathRelativeRoot: path_relative_root,
                isMobile: isMobile
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init: function() {
                let _this = this;
                if ($('.product_ranges').length) {
                    //Init first range swiper for customization
                    _this.initSwiper($('.product_ranges:first').attr('data-range'));
                }

                // Add a patch to the configuration by click
                $('.product_ranges .addProducts').on('click', function() {
                    if (!$(this).hasClass('.cloned')) {
                        let clone = $(this).clone();
                        clone.addClass("cloned");

                        // When adding products and text, images are all the same height but the bag has a different size, we need to adjust the position of the customizations depending on the bag size on the picture
                        const array_positions = [
                            { taille: 'TU', coef: .7 },
                            { taille: 'XS', coef: .7 },
                            { taille: 'S', coef: .65 },
                            { taille: 'M', coef: .60},
                            { taille: 'L', coef: .55 },
                            { taille: 'L porté épaule', coef: .55},
                            { taille: 'XL', coef: .5 }
                        ];

                        if (!_this.isMobile) {
                            clone.css({
                                'z-index' : 2,
                                "position" : "absolute",
                                "top": `${parseFloat(($('.configuration_picture').position().top + $('.configuration_picture').outerHeight()) * (array_positions.find(item => item.taille === $('.configuration_picture').attr('data-size')).coef))}px`,
                                "left" : (parseFloat(($('.configuration_picture').position().left + $('.configuration_picture').outerWidth()) / 2) + "px")
                            });
                        } else {
                            clone.css({
                                'z-index' : 2,
                                "position" : "absolute",
                                "top" : "0",
                                "left" : "0",
                                "transform": `translate(${parseFloat(($('.configuration_picture').position().left + $('.configuration_picture').outerWidth()) / 2)}px, ${parseFloat(($('.configuration_picture').position().top + $('.configuration_picture').outerHeight()) * (array_positions.find(item => item.taille === $('.configuration_picture').attr('data-size')).coef))}px)`,

                            });
                        }
                        clone.attr('data-rotation',0);
                        clone.attr('data-angle',0);
                        clone.attr('data-x',parseFloat(($('.configuration_picture').position().left + $('.configuration_picture').outerWidth()) / 2));
                        clone.attr('data-y', parseFloat(($('.configuration_picture').position().top + $('.configuration_picture').outerHeight()) * (array_positions.find(item => item.taille === $('.configuration_picture').attr('data-size')).coef)));
                        wShop.$refs.wConfigurator.$children[0].selectItem(clone);
                        $('.configurator_visual .picture_container:first-of-type').append(clone);
                        wShop.$refs.wConfigurator.$children[0].initDraggableItems();
                        wShop.$refs.wConfigurator.$children[0].saveCustomization();

                    }
                })
            },

            initSwiper: function(target) {
                let _this = this;
                let element = $('.product_ranges[data-range="'+ target +'"]');
                if (element.length) {
                    let range_swiper = new Swiper(element, {
                        loop: false,
                        slidesPerView: 'auto',
                        roundLengths: true,
                        simulateTouch: false,
                        navigation: {
                            nextEl: '.product_ranges[data-range="'+ target +'"] .swiper-button-next',
                            prevEl: '.product_ranges[data-range="'+ target +'"] .swiper-button-prev'
                        },
                        on: {
                            // On init, pass true to method, in order to call initDraggables from configurator-visual
                            init: _this.initDraggableItems(true)
                        },
                        allowTouchMove: configuratorStore.state.device == 'desktop' ? true : false
                    });

                    $(window).on('resize', function() {
                        range_swiper.update();
                    })
                }

            },

            initDraggableItems: function(swipe_only = false) {
                let _this = this;
                if ($('.configurator_customization .addProducts').length) {
                    if (!_this.isMobile) {
                        $('.configurator_customization .addProducts').draggable({
                            containment: ".configurator",
                            helper: "clone",
                            appendTo: ".pictures_wrapper",
                            zIndex: 2,
                            stop: function(event, ui) {
                                _this.configuratorStore.checkSuperpositions();

                                // Init draggable items from configurator-visual
                                if (swipe_only) {
                                    wShop.$refs.wConfigurator.$children[0].initDraggableItems();
                                }
                            }
                        });
                    }
                }
            },

            handleActiveCustomization: function(event) {
                let target_range = $(event.target).closest('.customization_title').attr('data-range');
                let _this = this;

                if ((target_range != 'broderie') && $('.configurator_configure').hasClass('broderie_in')) {
                    $('.configurator_configure').removeClass('broderie_in');
                }

                // Handle active customization TITLE
                if (!$('.customization_title[data-range="' + target_range + '"].active').length) {
                    $('.customization_title.active').removeClass('active');
                    $('.customization_title[data-range="' + target_range + '"]').addClass('active');
                }

                // Handle active customization CONTENT
                if (!$('.customize_content[data-range="' + target_range + '"].active').length) {
                    $('.customize_content.active').removeClass('active');
                    $('.customize_content[data-range="' + target_range + '"]').addClass('active');
                }

                // Init current swiper only if needed (when wrapper_size exceeds)
                if ($('.product_ranges[data-range="'+ target_range +'"]').find('.swiper-wrapper').get(0).scrollWidth > $(window).outerWidth()) {
                    _this.initSwiper(target_range);
                }
            },

            validateStep: function(stepId) {
                let _this = this;
                let nextStep = '';
                $.each(_this.customizationSteps, function(index, value) {
                    if(value=='addProduct') {
                        if (_this.customizationSteps[(index+1)] != undefined) {
                            nextStep = _this.customizationSteps[(index+1)];
                            DataLayerTrigger.CustomCabas(5, 'select custom logo', 'click', 'custom logo');
                        }
                    }
                });

                if (nextStep) {
                    _this.selectCustomizationStep(stepId, nextStep);
                }
                else {
                    this.configuratorStore.nextStep();
                }
            },

            selectCustomizationStep: function(stepId, customizationStep) {
                this.$emit('selectCustomizationStep',stepId,customizationStep);
            },

            setSelectedCustomizationStep: function (customizationStep) {
                this.getSelectedCustomizationStep = customizationStep;
            },

            moveKeyFirst(obj, keyToMove) {
                if (!(keyToMove in obj)) {
                    return obj; // Retourne l'objet inchangé si la clé n'existe pas
                }

                const newObj = { [keyToMove]: obj[keyToMove] }; // Ajoute la clé à déplacer en premier

                for (const key in obj) {
                    if (key !== keyToMove) {
                        newObj[key] = obj[key]; // Ajoute les autres clés ensuite
                    }
                }

                return newObj;
            }
        }
    }

}());
