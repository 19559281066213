let wConfiguratorProductDescription = (function() {
    let template = `
        <section class="configurator_product_description">
            <span class="close close_pop close_description" @click="hideDescription"></span>

            <div class="info_disclaimer_title_container">
                <img class="tooltip_img" :src="pathRelativeRoot+'img/configurator/icons/information.svg'" alt="information" />
                <span class="info_disclaimer_title">${Translator.translate('info_disclaimer_title')}</span>
            </div>

            <p class="recap_disclaimer">${Translator.translate('configurator_recap_disclaimer')}</p>

            <img class="description_img" :src="pathRelativeRoot+'img/configurator/refonte/description_img.jpg'" alt="${Translator.translate('info_disclaimer_title')}" />
        </section>
    `;

    return {
        name: 'wConfiguratorProductDescription',
        template: template,
        components: {
        },
        props: {
            product: {type: Object}
        },
        filters: {
            translate: Translator.translate,
        },
        data() {
            return {
                dimensions: "",
                origin: "",
                composition: "",
                configuratorStore: configuratorStore,
                pathRelativeRoot: path_relative_root,
            }
        },
        watch: {
            product: function() {
                this.init();
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init: function() {
                this.getProductCharacteristics();
            },

            getProductCharacteristics: function() {
                let _this = this;
                $.each(_this.product.characteristics, function (index, characteristic) {
                    if (characteristic.label_noaccent == 'dimensions') {
                        _this.dimensions = characteristic.value;
                    }
                    else if (characteristic.label_noaccent == 'origine') {
                        _this.origin = characteristic.value;
                    }
                    else if (characteristic.label_noaccent == 'composition') {
                        _this.composition = characteristic.value;
                    }
                });
            },

            displayDescription: function() {
                if (!$('.configurator_product_description.opened').length) {
                    $('.configurator_product_description.opened').addClass('opened');
                    $('.configurator_product_description.opened').fadeIn(300);
                }
            },

            hideDescription: function() {
                if ($('.configurator_product_description.opened').length) {
                    $('.configurator_product_description.opened').fadeOut(300);
                    $('.configurator_product_description.opened').removeClass('opened');
                }
            },
        }
    }

}());
