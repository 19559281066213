// Pretty straightforward: this formats a price
class ConfiguratorStore {
    constructor() {
        this.state = {
            langId: 0,
            siteId: 0,
            subcategoryId: 0,
            step: 1,
            maxStep: 1,
            displayDescription: false,
            customizedProduct: false,
            selectedFilters: {},
            searchStep: 1,
            filteredProducts: [],
            textCustomizationParams: {
                limit: 1,
                fixed: true,
                position: 'centered'
            },
            savedCustomizationId: 0,
            customizationData: {},
            errorSuperposition: false,
            configurator: false,
            device: window.dataLayer[0].context.navigation.device
        };

        this.sizesOrder = {
            1: [
                'Baby',
                'Petit',
                'Moyen',
                'Moyen +',
                'Moyen ++',
                'Grand',
                'Nord Sud',
                'XS',
                'S',
                'M',
                'L porté épaule',
                'L',
                'XL',
            ],
            2: [
                'Baby',
                'Small',
                'Medium',
                'Medium +',
                'Medium ++',
                'Large',
                'North South',
                'XS',
                'S',
                'M',
                'Shoulder strap L',
                'L',
                'XL',
            ]
        };

        this.previewSwiper = {};
    }

    setStep(step) {
        this.state.step = step;
    }
    getStep() {
        return this.state.step;
    }

    nextStep() {
        if (this.getStep() < this.state.maxStep) {
            this.setStep(this.getStep() + 1);
        } else {
            alertV2(translate('you_have_finished_the_customization'));
        }
    }

    displayDescription() {
        this.state.displayDescription = true;
    }
    hideDescription() {
        this.state.displayDescription = false;
    }
    getDisplayDescription() {
        return this.state.displayDescription;
    }

    getTextCustomizationParams() {
        return this.state.textCustomizationParams
    }

    getCustomizedProduct() {
        return this.state.customizedProduct;
    }
    setCustomizedProduct(product) {
        this.state.customizedProduct = product;

        //size label from characteristics
        $.each(product.characteristics, function (key, characteristic) {
            if (characteristic.label_noaccent == 'taille' || characteristic.label_noaccent == 'size') {
                configuratorStore.state.customizedProduct.sizeCharacteristic = characteristic.value;
            }
        });

        //categories ids
        configuratorStore.state.customizedProduct.categoriesIds = [];
        configuratorStore.state.customizedProduct.subCategoriesIds = [];
        $.each(product.categories, function (key, category) {
            configuratorStore.state.customizedProduct.categoriesIds.push(category.categorie_id);
            configuratorStore.state.customizedProduct.subCategoriesIds.push(category.sscategorie_id);
        });

    }

    setSelectedFilter(filterName, value) {
        this.state.selectedFilters[filterName] = value;
    }

    checkSuperpositionBetweenTwoItems(item1, item2) {
        let x1 = item1.offset().left;
        let y1 = item1.offset().top;
        let h1 = item1.outerHeight(true);
        let w1 = item1.outerWidth(true);
        let b1 = y1 + h1;
        let r1 = x1 + w1;
        let x2 = item2.offset().left;
        let y2 = item2.offset().top;
        let h2 = item2.outerHeight(true);
        let w2 = item2.outerWidth(true);
        let b2 = y2 + h2;
        let r2 = x2 + w2;

        if (b1 < y2 || y1 > b2 || r1 < x2 || x1 > r2) {
            return false;
        }

        return true;
    }
    checkSuperpositions() {
        let _this = this;
        _this.state.errorSuperposition = false;


        $('.picture_container:visible').each(function () {
            let items = $(this).children('.addProducts.cloned');
            if (items.length > 1) {
                $.each(items, function (itemIndex, item1) {
                    $.each(items, function (item2Index, item2) {
                        if (item1 != item2) {
                            if (_this.checkSuperpositionBetweenTwoItems($(item1), $(item2))) {
                                _this.state.errorSuperposition = true;
                            }
                        }
                    });
                });
            }
        });

    }
    getSizeCharacteristicLabel() {
        return this.sizeCharacteristicLabel[this.state.langId];
    }
    getLangId() {
        return this.state.langId;
    }
}

let configuratorStore = new ConfiguratorStore();


var formatPriceVue = (function () {

    var THREEDIGITS = new RegExp("\\d{3}", "g");
    var FORMAT = window.price_format_plain;

    // Splits a number into chunks of 3 digits
    function splitThousands(nmbr) {

        var f_chunk = '',
            chunk_length;

        nmbr = String(nmbr);

        chunk_length = nmbr.length % 3;

        if (chunk_length) {

            f_chunk = nmbr.slice(0, chunk_length);
            nmbr = nmbr.substr(chunk_length);
        }

        return f_chunk + nmbr.replace(THREEDIGITS, function (match) {

            return ' ' + match;
        });
    }

    return function (price) {
        var CURRENCY = window.siteCurrency;
        var FORMAT = window.price_format_plain;

        if (CURRENCY === undefined) {
            return price;
        }

        var output;

        price = Math.round(price * 100) / 100; // 2 chiffres après la virgule
        if (price.constructor !== String) {

            price = price.toString(10);
        }

        price = price.replace('.', ',');
        price = price.split(',');

        // Si les centime sont égal à 0 alors on change le format
        if (price[1] === undefined || (parseFloat(price[1]) < 10 && price[1].length < 2) || price[1] === 0) {
            FORMAT = window.price_format_plain_no_cent;
        }

        output = FORMAT.replace('[<unit>]', splitThousands(price[0]));
        output = output.replace('[<symbol_right>]', CURRENCY.symbol_right);
        output = output.replace('[<symbol_left>]', CURRENCY.symbol_left);

        if (price[1] === undefined) {

            price[1] = '00';
        } else if (parseFloat(price[1]) < 10 && price[1].length < 2) {

            price[1] = '0' + price[1];
        }

        output = output.replace('[<decimal>]', price[1]);
        output = output.replace('[<code>]', CURRENCY.code);

        return output;
    };
}());

function RemoveAccents(str) {
    var accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    var accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
    str = str.split('');
    var strLen = str.length;
    var i, x;
    for (i = 0; i < strLen; i++) {
        if ((x = accents.indexOf(str[i])) != -1) {
            str[i] = accentsOut[x];
        }
    }
    return str.join('');
}

let wConfigurator = (function () {
    let template = `
        <section class="configurator">
            <div class="page_loading" :class="{'actif': isLoading}">
                <div class="loading">
                    <div class="loader"></div>
                </div>
            </div>

            <div id="confirmModal" class="lightbox">
                <span class="close_btn close_pop" @click="handleCancel">X</span>
                <span class="modal_title">{{ modal.title }}</span>
                <div class="modal_content">
                    <p>{{ modal.content }}</p>
                </div>
                <div class="modal_submits">
                    <button class='button primary' @click="modal.confirmAction">{{ 'popup_confirm'|translate }}</button>
                    <button class='button secondary' @click="handleCancel">{{ 'btn_cancel'|translate }}</button>
                </div>
            </div>

            <div class="configurator_content">
                <div class="configurator_leftside">
                    <div v-if="customizedProduct">
                        <w-configurator-visual
                            :key="customizedProduct.id"
                            :product="customizedProduct"
                            @saveCustomizationData="saveCustomizationData"
                            ref="configuratorVisual"
                        ></w-configurator-visual>
                        <w-configurator-product-description
                            :product="configuratorStore.state.customizedProduct" ></w-configurator-product-description>
                    </div>

                </div>
                <div class="configurator_rightside">
                    <div class="configurator_upside">
                        <div class="configuration_step_back" @click="goToSearchProducts()">
                            <span>${Translator.translate('back')}</span>
                        </div>
                        <div class="steps">
                            <div class="content">
                                <w-configurator-search-products
                                    v-if="displaySearchProducts"
                                    :lang-id="langId"
                                    :products="products"
                                    :filtered-products="filteredProducts"
                                    :category-id="categoryId"
                                    @updateFilteredProducts="setFilteredProducts"
                                    @updateLoader="updateLoader"
                                    @validateSearchProducts="validateSearchProducts"
                                    :recap-filtered-product="recapFilteredProduct"
                                    ref="configuratorSearchProducts"
                                ></w-configurator-search-products>
                            </div>
                        </div>

                        <w-configurator-customization
                            :key="customizedProduct.id"
                            v-if="customizedProduct && displayCustomization"
                            :isLoading="isLoading"
                            :original-product="customizedProduct"
                            :array_ecusson_size_configurator="array_ecusson_size_configurator"
                            @setConfigurator="setConfigurator"
                            @updateLoader="updateLoader"
                            @addText="addText"
                        ></w-configurator-customization>
                    </div>

                    <div class="configurator_downside" v-if="filteredProducts.length">
                        <p class="info_delivery cache"><span v-html="$options.filters.translate('customization_delivery_data')"></span> {{ deliveryDate }}</p>

                        <p class="info_customization">{{ 'product_price' | translate }} <span class="prices" v-html="customizedProductPrice"></span></p>

                        <div id="info_customization-content">
                            <ul>
                                <li class="detail" v-for="item in customizationCost" v-html="item.title+' '+item.price"></li>
                            </ul>
                        </div>
                        <div class="info_total_container">
                            <p class="info_total"><span class="total_price">{{ 'total_price' | translate }}</span> <span class="prices" v-html="totalPrice"></span></p>
                        </div>


                        <div class="buttons_container">
                            <button class="button secondary" v-if="productIsCustomized" @click="saveCustomization(false)">{{ 'save' | translate }}</button>
                            <button class="button primary" :class="{'loading': isLoadingAddBasket}" @click="addCustomizedProductToBasket">{{ 'add_to_basket' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>


            <div v-if="isMobile" class="configurator_alert_box">
                <button class="close_pop close"></button>
                <div class="txt_alert"></div>

                <div class="button primary close_abox">${Translator.translate('close')}</div>
            </div>
            <div v-if="isMobile" class="configurator_shade"></div>
        </section>

    `;

    return {
        name: 'wConfigurator',
        template: template,
        components: {
            wConfiguratorSearchProducts,
            wConfiguratorCustomization,
            wConfiguratorPreviewProduct,
            wConfiguratorProductDescription,
            wConfiguratorVisual
        },
        props: {
            langId: {
                type: Number,
                default: 1
            },
            siteId: {
                type: Number,
                default: 1
            },
            categoryId: {
                type: Number,
                default: 0
            },
            subcategoryId: {
                type: Number,
                default: 0
            },
            preselectedProductId: {
                type: Number,
                default: 0
            },
            preselectedConfiguration: {
                type: Number,
                default: 0
            },
            siteUrl: {
                type: String,
            },
            array_ecusson_size_configurator: {
                type: Object,
            }
        },
        filters: {
            translate: Translator.translate,
        },
        data() {
            return {
                isLoading: true,
                displaySearchProducts: true,
                displayCustomization: false,
                products: [],
                filteredProducts: [],
                step: "1",
                customizedProduct: false,
                customizationData: [],
                configurator: {},
                storedCustomizationCost: 0,
                configuratorStore: configuratorStore,
                errors: [],
                displayShareBox: false,
                getSelectedCustomizationdetail: false,
                recapFilteredProduct: false,
                isMobile: isMobile,
                modal: {
                    title: '',
                    content: '',
                    confirmAction: ''
                },
                isLoadingAddBasket: false
            }
        },
        computed: {
            deliveryDate: function () {

            },
            customizationCost: function () {
                return this.calculateCustomizationCost();
            },
            customizedProductPrice: function () {
                if (this.customizedProduct.price) {
                    return formatPriceVue(this.customizedProduct.price);
                }
                return 0;
            } ,
            totalPrice: function () {
                if (this.filteredProducts.length == 1) {
                    return formatPriceVue(this.storedCustomizationCost + parseFloat(this.filteredProducts[0].price));
                } else if (this.filteredProducts.length) {
                    return formatPriceVue(this.filteredProducts[0].price);
                }
                return 0;
            },
            displayDescription: function () {
                return this.configuratorStore.getDisplayDescription();
            },
            productIsCustomized: function () {
                if (this.customizationData.steps != undefined && this.customizationData.steps.length) {
                    return true;
                }
                return false;
            }

        },
        mounted() {
            this.init();
        },
        methods: {
            init: function () {
                this.configuratorStore.state.langId = this.langId;
                this.configuratorStore.state.siteId = this.siteId;
                // this.configuratorStore.state.subcategoryId = this.subcategoryId;
                // Set to ""
                this.configuratorStore.state.subcategoryId = "";
                this.initProducts();
            },

            initProducts: function () {
                $.ajax({
                    url: path_relative_root + 'ajax_configurator_load_products.php',
                    type: 'post',
                    async: false,
                    data: {
                        category_id: this.categoryId,
                        mininalLoad: true
                    },
                    dataType: 'json',
                    success: this.handleInitProductsResults
                });
            },
            handleInitProductsResults: function (products) {
                this.setProducts(products);
                if (this.preselectedProductId) {
                    this.preselectProduct(products);
                }
                else {
                    this.setFilteredProducts(products);
                }
                this.isLoading = false;
                // Remove loader on page load
                if (document.querySelector('.holder_configurator_page').classList.contains('loading')) {
                    document.querySelector('.holder_configurator_page').classList.remove('loading');
                }
            },

            preselectProduct: function (products) {
                let _this = this;
                $.each(products, function (index, product) {
                    if (product.id == _this.preselectedProductId) {
                        _this.setFilteredProducts([product]);
                        _this.setCustomizedProduct(product);
                    }
                });
                _this.validateSearchProducts();
            },

            setCustomizedProduct: function (product) {
                this.customizedProduct = product;
                this.configuratorStore.setCustomizedProduct(product);
            },


            setFilteredProducts: function (products) {
                this.filteredProducts = products;
            },
            getFilteredProducts: function () {
                return this.filteredProducts;
            },
            setProducts: function (products) {

                this.products = products;
            },
            getProducts: function () {
                return this.products;
            },

            updateLoader: function (value) {
                this.isLoading = value;
            },

            setConfigurator: function (configurator) {
                this.configurator = configurator;
                this.configuratorStore.state.configurator = configurator;
            },

            validateSearchProducts: function () {


                if (document.querySelector('body').classList.contains('noScroll')) {
                    document.querySelector('body').classList.remove('noScroll');
                }

                if (!this.customizedProduct) {
                    this.displaySearchProducts = false;
                    this.displayCustomization = true;
                    this.customizedProduct = this.filteredProducts[0];
                    this.setCustomizedProduct(this.filteredProducts[0]);

                    this.datalayerCustomCabas(4, 'select color', 'click', this.customizedProduct.colors[0].name);


                    // When displaying customization, add special class to body
                    if (!document.querySelector('body').classList.contains('configurator_configure')) {
                        document.querySelector('body').classList.add('configurator_configure');

                        if (!document.querySelector('body').classList.contains('noScroll')) {
                            document.querySelector('body').classList.add('noScroll');

                        }
                    }

                } else {
                    if (this.customizedProduct.id == this.filteredProducts[0].id) {
                        this.displaySearchProducts = false;
                        this.displayCustomization = true;
                        // this.configuratorStore.hideDescription();

                        if (this.preselectedConfiguration) {
                            let _this = this;
                            setTimeout(function () {
                                _this.$refs.configuratorVisual.loadConfiguration(_this.preselectedConfiguration);
                            }, 50);
                        }
                    } else {
                        if (confirm('Vous allez changer de produit !!! vous allez perdre toute la configuration')) {
                            this.displaySearchProducts = false;
                            this.displayCustomization = true;
                        }
                    }

                    // When displaying customization, add special class to body
                    if (!document.querySelector('body').classList.contains('configurator_configure')) {
                        document.querySelector('body').classList.add('configurator_configure');

                        if (!document.querySelector('body').classList.contains('noScroll')) {
                            document.querySelector('body').classList.add('noScroll');

                        }
                    }
                }

                this.configuratorStore.setStep(2);
            },

            goToSearchProducts: function () {
                if (this.configuratorStore.getStep() > 1) {
                    this.confirmModal('customization_return_search_products', this.confirmActionReset);
                }
            },

            confirmActionReset: function () {
                this.reset();
                DataLayerTrigger.CustomCabas(6, 'finalize custom', 'click', 'reset');
                closeMultiShad('confirmModal');
                if ($('body').hasClass('configurator_configure')) {
                    $('body').removeClass('configurator_configure')
                }
                if ($('body').hasClass('noScroll')) {
                    $('body').removeClass('noScroll')
                }
            },

            datalayerCustomCabas: function (stepIndex, stepName, eventAction, eventLabel) {
                DataLayerTrigger.CustomCabas(stepIndex, stepName, eventAction, eventLabel)
            },

            reset: function () {
                let _this = this;
                $('.page_loading .error').remove();
                if (!$('.page_loading .loading').length) {
                    $('.page_loading').append('<div class="loading"><div class="loader"></div></div>');
                }
                _this.isLoading = true;
                _this.displaySearchProducts = true;
                _this.displayCustomization = false;
                _this.customizedProduct = false;
                _this.customizationData = {};
                _this.setFilteredProducts(_this.configuratorStore.state.filteredProducts);
                _this.configuratorStore.state.maxStep = 1;
                _this.isLoading = false;
                _this.recapFilteredProduct = _this.configuratorStore.state.recap;
            },

            saveCustomizationData: function (customizationData) {
                this.customizationData = customizationData;
            },

            addText: function () {
                this.$refs.configuratorVisual.onAddText();
            },

            calculateCustomizationCost: function () {
                let _this = this;
                let cost = 0;
                let custom_products = [];
                $.each(_this.customizationData.steps, function (key, customizationStep) {
                    $.each(customizationStep.customization, function (keyItem, item) {
                        //prix broderie
                        if (_this.configurator.option_broderie != undefined && _this.configurator.option_broderie.products.id == item.product_id) {
                            cost += parseFloat(_this.configurator.option_broderie.products.price);
                            var prod_name = translate('product_embroidery');
                            custom_products.push({title : prod_name,  price : formatPriceVue(_this.configurator.option_broderie.products.price)});
                        }

                        //prix ecussons
                        if (_this.configurator.option_ecusson != undefined) {
                            $.each(_this.configurator.option_ecusson.products, function (keyProductCat, productCat) {
                                $.each(productCat, function (keyProduct, product) {
                                    if (product.id == item.product_id) {
                                        cost += parseFloat(product.price);
                                        custom_products.push({title : product.title,  price : formatPriceVue(product.price)})
                                    }
                                });
                            });
                        }
                    });
                });
                _this.storedCustomizationCost = cost;
                return custom_products;
            },
            selectCustomizationStep: function (action) {
                if (this.productIsCustomized != undefined && this.productIsCustomized == true && action ==='show') {
                    this.getSelectedCustomizationdetail = true;
                    DataLayerTrigger.CustomCabas(6, 'finalize custom', 'click', 'prix');
                }
            },

            computeCustomizationData: function () {
                let _this = this;

                 // get all products data from loaded data
                $.each(_this.customizationData.steps, function (index1, customizationData) {
                    $.each(customizationData.customization, function (index2, product) {
                        let found_product = false;
                        if (_this.configurator.option_broderie.products.id == product.product_id) {
                            found_product = _this.configurator.option_broderie.products;
                        } else {
                            $.each(_this.configurator.option_ecusson.products, function (index3, customizeProducts) {
                                $.each(customizeProducts, function (index4, customizeProduct) {
                                    if (product.product_id == customizeProduct.id) {
                                        customizeProduct.type = index3;
                                        found_product = customizeProduct;
                                    }
                                });
                            });
                        }
                        if (found_product !== false) {
                            $.each(found_product, function (attribute, value) {
                                // don't need id beacause we already got product_id
                                // don't erase picture data
                                if (attribute == 'id' || attribute == 'picture') {
                                    return;
                                }
                                product[attribute] = value;
                            });
                        }
                    });
                });
            },

            // Permet de sauvegarder la configuration
            addCustomizedProductToBasket: function () {
                let _this = this;

                _this.isLoadingAddBasket = true;

                _this.computeCustomizationData();

                //send main product to get virtual basket id in session
                let response = _this.sendMainProductToBasket();
                if (response.error !== undefined && response.error !== 0) {
                    if (response.error.msg !== '' && response.error.msg !== undefined) {
                        alertV2(response.error.msg);
                        _this.isLoadingAddBasket = false;
                    }
                } else {
                    let virtualBasketId = response.panier_virtuel_id;

                    // Sauvegarde de la configuration
                    $.ajax({
                        url: path_relative_root + "ajax_configurator_save_customization.php",
                        type: "post",
                        dataType: 'json',
                        data: {
                            customizationData: JSON.stringify(_this.customizationData),
                            addToBasket: true,
                            basket_virtuel_id: virtualBasketId
                        },
                        success: function (res) {
                            if (res.success) {
                                //envoi des produits de configuration au panier
                                _this.addCustomizationProductsToBasket(virtualBasketId);
                                DataLayerTrigger.CustomCabas(6, 'finalize custom', 'click', 'ajouter au panier');
                            } else {
                                alertV2(res.error);
                                _this.isLoadingAddBasket = false;
                            }
                        }
                    });
                }
            },

            sendMainProductToBasket: function () {
                let product = this.configuratorStore.state.customizedProduct;
                product.configurator_product = false;
                product.reinit_basket_virtuel_parent_id = true;
                return this.sendToBasket(product);
            },


            // Ajout au panier des autres produits
            addCustomizationProductsToBasket: function (virtualBasketId) {
                let _this = this;
                let arrayProductDuplicate = [];
                let customizationDataCopy = JSON.parse(JSON.stringify(_this.customizationData.steps));

                $.each(_this.customizationData.steps, function (index, customizationData) {
                    $.each(customizationData.customization, function (indexProduct, product) {
                        product.id = product.product_id;
                        product.configurator_product = true;

                        let isDuplicate = false;
                        let qte = _this.checkQteProduct(product, customizationDataCopy);
                        if (qte > 1) {
                            arrayProductDuplicate.forEach(productDuplicate => {
                                if (productDuplicate.id === product.product_id && productDuplicate.color === product.color_id && productDuplicate.size === product.size_id) {
                                    isDuplicate = true
                                }
                            });

                            if (!isDuplicate) {
                                arrayProductDuplicate.push({'id': product.id, 'color': product.color_id, 'size': product.size_id});
                            }
                        }

                        if (!isDuplicate) {
                            let response = _this.sendToBasket(product, qte, virtualBasketId);
                            // On regarde si il y a eu une erreur dans l'ajout (cas plus de stock)
                            if (response.error !== undefined) {
                                if (response.error.msg !== '' && response.error.msg !== undefined) {
                                    _this.errors.push(response.error.msg);
                                }
                            }
                        }
                    });
                });

                // if errors, display else update small basket
                if (_this.errors.length) {
                    alertV2(_this.errors.join('<br>'));
                    _this.isLoadingAddBasket = false;

                    //remove main product from basket
                    this.removeVirtualBasket(virtualBasketId);
                } else {
                    this.updateSmallBasket();
                }

            },

            checkQteProduct: function (product_main, customizationDataCopy) {
                let _this = this;
                let qte = 0;
                let customizationDataCopyCopy = JSON.parse(JSON.stringify(customizationDataCopy));
                $.each(customizationDataCopyCopy, function (index, customizationData) {
                    $.each(customizationData.customization, function (indexProductCopy, productCopy) {
                        if (product_main.id === productCopy.product_id && product_main.color_id === productCopy.color_id && product_main.size_id === productCopy.size_id) {
                            qte++;
                        }
                    });
                });
                return qte;
            },

            // Permet d'envoyer au panier le produit passé en parametre
            sendToBasket: function (product, qte= 1, virtualBasketId = 0) {
                if (virtualBasketId === 0) {
                    virtualBasketId = product.basket_virtuel_parent_id;
                }
                let response = {};
                $.ajax({
                    url: path_relative_root + "ajax_upd_panier.php",
                    type: "post",
                    dataType: 'json',
                    async: false,
                    data: {
                        configurator: true,
                        idprod: product.id,
                        idcouleur: product.color_id,
                        idtaille: product.size_id,
                        panierid: 0,
                        basket_virtuel_parent_id: virtualBasketId,
                        qte: qte,
                        configurator_product: true,
                        dataTypeReturn: 'json',
                        reinit_basket_virtuel_parent_id: product.reinit_basket_virtuel_parent_id != undefined ? product.reinit_basket_virtuel_parent_id : false,
                        virtual_basket: product.reinit_basket_virtuel_parent_id != undefined ? product.reinit_basket_virtuel_parent_id : false
                    },
                    success: function (res) {
                        if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null) {
                            var dataLayer = window.dataLayer || [];
                            dataLayer.push({event: 'add_to_cart', ecommerce: {currency: res.dataLayerInfos['actionField']['currency'],value: res.dataLayerInfos['actionField']['revenue'],items: res.dataLayerInfos['items']}});
                        }
                        response = res;
                    }
                });
                return response;
            },

            // supprime un produit du panier virtuel
            removeVirtualBasket: function(virtualBasketId) {
                let response = {};
                $.ajax({
                    url: path_relative_root + create_link('order_basket'),
                    type: "post",
                    dataType: 'json',
                    async: false,
                    data: {
                        action: 'del',
                        panierId: virtualBasketId,
                        cad: 'non',
                        qteProd: 1,
                        returnHtml: 'ajax'
                    },
                    success: function (res) {
                        response = res;
                    }
                });
                return response;
            },

            updateSmallBasket: function () {
                // Mise à jour du panier top
                let response_basket = ajax_file(path_relative_root + 'ajax_show_basket.php');

                let cart_top = $("#cart_top");
                cart_top.html(response_basket);

                if (!this.isMobile) {
                    $("#cart_list").lionbars();
                    cart_top.on('mouseenter touchstart', function () {
                        $("#cart_list").lionbars();
                    });
                }

                location.href = path_relative_root + create_link('order_basket');
            },

            saveCustomization: function (returnResult, share) {
                let _this = this;
                _this.isLoading = true;
                _this.computeCustomizationData();
                let result = false;
                $.ajax({
                    url: path_relative_root + "ajax_configurator_save_customization.php",
                    type: "post",
                    dataType: 'json',
                    async: returnResult ? false : true,
                    data: {
                        preselectedConfiguration: _this.preselectedConfiguration,
                        customizationData: JSON.stringify(_this.customizationData),
                        share: share ? true : false
                    },
                    success: function (res) {
                        if (res.success) {
                            _this.configuratorStore.state.customizationData = _this.customizationData;
                            if (returnResult && res.config != undefined) {
                                result = res.config;
                            } else {
                                $('.configurator_downside .button.secondary').addClass('saved');
                                if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null) {
                                    var dataLayer = window.dataLayer || [];
                                    dataLayer.push(res.dataLayer_object);
                                }
                                alertV2(translate('customization_saved'));
                                DataLayerTrigger.CustomCabas(6, 'finalize custom', 'click', 'enregistrer')
                            }
                        } else {
                            alertV2(res.error);
                        }
                        _this.isLoading = false;
                    }
                });


                if (returnResult) {
                    if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null) {
                        var dataLayer = window.dataLayer || [];
                        dataLayer.push(res.dataLayer_object);
                    }
                    _this.isLoading = false;
                    return result;
                }
            },

            toggleShareBox: function () {
                DataLayerTrigger.CustomCabas(6, 'finalize custom', 'click', 'partager');
                this.displayShareBox = !this.displayShareBox;
            },

            shareByMail: function () {
                let config = this.saveCustomization(true, true);
                this.configuratorStore.state.savedCustomizationId = config.pc_id;

                if ($('#sendfriendform').length) {
                    //add product_id in form
                    if ($('#sendfriendform input[name=product_id]').length) {
                        $('#sendfriendform input[name=product_id]').val(this.configuratorStore.state.customizedProduct.id);
                    } else {
                        $('#sendfriendform').prepend('<input type="hidden" name="product_id" value="' + this.configuratorStore.state.customizedProduct.id + '" />');
                    }
                    //add config_id
                    if ($('#sendfriendform input[name=config_id]').length) {
                        $('#sendfriendform input[name=config_id]').val(this.configuratorStore.state.savedCustomizationId);
                    } else {
                        $('#sendfriendform').prepend('<input type="hidden" name="config_id" value="' + this.configuratorStore.state.savedCustomizationId + '" />');
                    }


                    openMultiShad('sendfriendbox');
                }
            },

            shareByFacebook: function () {
                let routeId = 'configuratorV2_'+this.configuratorStore.state.siteId+'_cookie';
                let config = this.saveCustomization(true, true);

                let url = this.siteUrl + path_relative_root + create_link({
                    routeId: routeId,
                    pc_hash: config.pc_hash
                });

                window.open(

                    "https://www.facebook.com/share.php?u=" +
                    encodeURIComponent(url) +
                    "&t="
                );
            },

            pleaseSelectProduct: function () {
                alertV2(translate('please_select_product_first'));
            },

            confirmModal: function (msg, confirmAction) {
                this.modal.content = translate(msg);
                this.modal.confirmAction = confirmAction;
                openMultiShad('confirmModal');
            },

            handleCancel: function () {
                closeMultiShad('confirmModal');
            }

        }
    }

}());