jQuery.fn.rotate = function(degrees) {
    $(this).css({'-webkit-transform' : 'rotate('+ degrees +'deg)',
        '-moz-transform' : 'rotate('+ degrees +'deg)',
        '-ms-transform' : 'rotate('+ degrees +'deg)',
        'transform' : 'rotate('+ degrees +'deg)'});
    return $(this);
};

let wConfiguratorVisual = (function() {
    let template = `
        <section class="configurator_visual">
            <div class="pictures_leftmenu">
                <div class="tooltip information">
                    <img class="tooltip_img" :src="pathRelativeRoot+'img/configurator/icons/information.svg'" :alt="translateDescription" />
                    <span class="tooltiptext tooltip-right" @click="toggleDescription">{{ 'tooltips_info' | translate }}</span>
                </div>
                <div class="tooltip reset" @click="resetCustomization">
                    <img class="tooltip_img" :src="pathRelativeRoot+'img/configurator/icons/reset.svg'" :alt="translateReset" />
                    <span class="tooltiptext tooltip-right">{{ 'tooltips_init' | translate }}</span>
                </div>
            </div>

            <div class="pictures_container">
                <div class="pictures_wrapper">
                    <div class="picture_container"
                        v-for="(picture, letter) in pictures"
                        v-show="displayedPicture==letter">
                        <img :src="picture" :alt="letter" :data-size="(displayedPicture==letter) ? (customizedProduct.sizeCharacteristic ? customizedProduct.sizeCharacteristic : customizedProduct.sizes[0].name)  : ''" :class="{configuration_picture : (displayedPicture==letter)}"/>
                    </div>
                </div>
                <div class="error_superposition" v-if="configuratorStore.state.errorSuperposition">{{ 'alert_patch_2' | translate }}</div>
                <div class="error_superposition" v-else>{{ 'alert_patch_1' | translate }}</div>
            </div>

            <div
                class="zoom_container"
                v-if="displayZoom"
                v-html="zoomContent"
                :style="'background-color:'+configuratorStore.state.customizedProduct.colors[0].code"
                >
            </div>

        </section>
    `;

    return {
        name: 'wConfiguratorCustomizationAddProduct',
        template: template,
        components: {
            wConfiguratorCustomizationAddProduct
        },
        props: {
            product: {type: Object},
            array_ecusson_size_configurator: { type: Object }
        },
        filters: {
            translate: Translator.translate,
        },
        data() {
            return {
                pictures: {},
                displayedPicture: false,
                customizationData: {},
                selectedItem: {},
                intervalRotation: false,
                configuratorStore: configuratorStore,
                pathRelativeRoot: path_relative_root,
                displayZoom: false,
                zoomContent: '',
                customizedProduct: {},
                isMobile: isMobile // isMobile defined in views/main.php || views_mobile_new/main.php
            }
        },
        watch: {
            'configuratorStore.state.step': function(val) {
                this.displayPicture(val);
            },
            'configuratorStore.state.customizedProduct': function(product) {
                this.customizedProduct = product;
            }
        },
        beforeMount() {
            //init by product receive by props
            this.customizedProduct = this.product;
            this.initPictures();
        },
        mounted() {
            let _this = this;

            _this.init();

                // Only on customization step
            if ($('body.configurator_configure').length) {
                $(window).on('resize', function() {
                    _this.scrollToVisualBottom();
                });
            }
        },
        computed: {
            translateTurnOver: function() {
                return this.$options.filters.translate('turn_over');
            },
            translateDescription: function() {
                return this.$options.filters.translate('description');
            },
            translateTrash: function() {
                return this.$options.filters.translate('delete');
            },
            translateReset: function() {
                return this.$options.filters.translate('reinit');
            },
            tooltipFace: function() {
                if(this.displayedPicture == 'Z') {
                    return translate('verso');
                }
                else {
                    return translate('recto');
                }
            }
        },
        methods: {
            init: function() {
                this.initDroppableItems();

                // Only on customization step
                if ($('body.configurator_configure').length) {
                    this.scrollToVisualBottom();
                }
            },

            scrollToVisualBottom: function() {
                $(".configurator_leftside").scrollTop($(".configurator_visual")[0].scrollHeight);
            },

            initPictures: function() {
                let _this = this;
                if (_this.customizedProduct.pictures) {
                    $.each(_this.customizedProduct.pictures, function (letter, picture) {
                        _this.pictures[letter] = picture;
                    });
                    $.each(_this.customizedProduct.pictures, function (letter, picture) {
                        _this.displayedPicture = letter;
                        return false;
                    });
                }
            },

            initDraggableItems: function() {
                let _this = this;
                let selectors = '.configurator_visual .picture_container .addProducts';

                //check text params
                let textParams = this.configuratorStore.getTextCustomizationParams();
                if(textParams.fixed) {
                    selectors += ':not(.addTexts)';
                }

                if (isMobile) {
                    interact(selectors).draggable({
                        inertia: true,
                        listeners: {
                            startAxis: 'xy',
                            lockAxis: 'start',
                            move: _this.dragMoveListener,
                            end: _this.saveCustomization
                        }
                    });
                } else {
                    $(selectors).draggable({
                        zIndex:2,
                        containment: $(this).parents('.configurator'),
                        revert: "invalid",
                        stop: function(event, ui) {
                            $(this).attr(
                                "data-x",
                                parseFloat($(this).css("left")).toFixed(2)
                            );
                            $(this).attr(
                                "data-y",
                                parseFloat($(this).css("top")).toFixed(2)
                            );
                            _this.saveCustomization()
                            _this.configuratorStore.checkSuperpositions();
                        },
                        drag: function() {
                            // to move the picture_editor
                            _this.selectItem($(this));
                        },
                    });
                }

                $(selectors).on('click', function() {
                    _this.selectItem($(this));
                });

                if (!isMobile) {
                    $(selectors).hover(function() {
                        _this.displayZoomItem($(this));
                    },function() {
                        _this.hideZoomItem($(this));
                    });
                }
            },

            dragMoveListener: function(event) {
                var target = event.target;
                // keep the dragged position in the data-x/data-y attributes
                var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
                var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

                // translate the element
                target.style.webkitTransform =
                    target.style.transform =
                    'translate(' + x + 'px, ' + y + 'px)';

                // update the posiion attributes
                target.setAttribute('data-x', x);
                target.setAttribute('data-y', y);
            },

            dragMoveListener: function(event) {
                var target = event.target;
                // keep the dragged position in the data-x/data-y attributes
                var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
                var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

                // translate the element
                target.style.webkitTransform =
                    target.style.transform =
                    'translate(' + x + 'px, ' + y + 'px)';

                // update the posiion attributes
                target.setAttribute('data-x', x);
                target.setAttribute('data-y', y);
            },

            initDroppableItems: function() {
                let _this = this;
                $('.configurator_visual .picture_container img').droppable({
                    drop: function(event, ui) {
                        if (!$(ui.draggable).hasClass("cloned")) {
                            var clone = $(ui.helper)
                                .clone()
                                .addClass("cloned")
                                .css('z-index',2);

                            clone.attr('data-rotation',0);
                            clone.attr('data-x',parseFloat(ui.position.left).toFixed(2));
                            clone.attr('data-y',parseFloat(ui.position.top).toFixed(2));
                            _this.selectItem(clone);
                            $(this).parent().append(clone);
                            _this.initDraggableItems();
                        } else {
                            _this.selectItem(ui.draggable);
                        }
                        _this.saveCustomization();
                    }
                });
            },

            displayPicture: function(value) {
                // mapping entre étape et image à afficher
                switch (value) {
                    case 2 :
                    case "2" :
                        this.displayedPicture = 'B';
                        break;
                    case 3 :
                    case "3" :
                        // l'image V n'étant pas encore uploadée, j'utilise la B en attendant
                        // this.displayedPicture = 'V';
                        this.displayedPicture = 'B';
                        break;
                    default: this.displayedPicture = value;
                }
            },

            saveCustomization: function() {
                let _this = this;

                if (_this.intervalRotation) {
                    clearInterval(_this.intervalRotation);
                    _this.intervalRotation = false;
                }

                let product = _this.customizedProduct;
                let customization = {
                    product_id: product.id,
                    product: product,
                    steps: []
                };

                // get product picture data
                // because hidden pictures have no width and height
                let product_picture_size = {};
                let product_picture_visible = $('.picture_container:visible img.ui-droppable:first');
                product_picture_size.width = parseFloat(product_picture_visible.width());
                product_picture_size.height = parseFloat(product_picture_visible.height());

                $('.picture_container').each(function() {
                    let items = $(this).children('.addProducts.cloned');
                    let product_picture = $(this).children('img.ui-droppable:first');
                    let step_id = '';
                    let stepCustomization = [];
                    let letter = product_picture.attr('alt');
                    $.each(items, function (key, item) {
                        let object_item = $(item);
                        step_id = object_item.attr('step_id');

                        let x_percentage = (parseFloat(object_item.attr('data-x')) / product_picture_size.width) * 100;
                        let y_percentage = (parseFloat(object_item.attr('data-y')) / product_picture_size.height) * 100;

                        // Add remove customization cross
                        if (!object_item.find('.remove_customization').length && !object_item.hasClass('addTexts')) {
                            if (isMobile) {
                                object_item.find('.buttons_container').append('<span class="remove_customization"></span>');
                            } else {
                                object_item.append('<span class="remove_customization"></span>');
                            }

                            // Remove Customization
                            object_item.find('.remove_customization').on('click', function(){
                                isMobile ? $(this).parents('.addProducts.cloned').remove() : $(this).parent('.addProducts.cloned').remove();
                                _this.hideZoomItem();
                                _this.saveCustomization();
                            });
                        }

                        let add_item = {
                            product_id: object_item.attr('product_id'),
                            color_id: object_item.attr('color_id'),
                            size_id: object_item.attr('size_id'),
                            position: {
                                x: object_item.attr('data-x'),
                                y: object_item.attr('data-y'),
                                x_percentage: x_percentage.toFixed(2),
                                y_percentage: y_percentage.toFixed(2),
                            },
                            rotation: object_item.attr('data-rotation')
                        };

                        if(object_item.hasClass('addTexts')) {
                            add_item.text = {
                                font: object_item.attr('customization-font'),
                                font_name: object_item.attr('customization-font-name'),
                                color: object_item.attr('customization-color'),
                                color_name: object_item.attr('customization-color-name'),
                                size: object_item.attr('customization-size'),
                                size_number: object_item.attr('customization-size-number'),
                                value: object_item.html()
                            }
                        }
                        else {
                            add_item.picture = {
                                src: object_item.children('img').attr('src'),
                                width: parseFloat(object_item.width()),
                                width_percentage: (parseFloat(object_item.attr('data-width')) / product_picture_size.width * 100).toFixed(2),
                                height: parseFloat(object_item.height()),
                                height_percentage: (parseFloat(object_item.attr('data-height')) / product_picture_size.height * 100).toFixed(2)
                            };
                        }

                        stepCustomization.push(add_item);

                    });
                    if (step_id && stepCustomization.length) {
                        customization.steps.push({
                            step: step_id,
                            letter: letter,
                            picture: {
                                src: product_picture.attr('src'),
                                width: product_picture_size.width,
                                height: product_picture_size.height
                            },
                            customization: stepCustomization
                        });
                    }


                });
                this.$emit('saveCustomizationData',customization);
            },

            selectItem: function(item) {
                this.selectedItem = item;
                let _this = this;

                if (isMobile) {
                    if (!$(item[0]).find('.rotation-handle').length) {
                        $(item[0]).append('<div class="buttons_container"><span class="rotation-handle"></span></div>')
                        $(item[0]).children('.rotation-handle').show();
                    }
                    interact('.rotation-handle').draggable({
                        onstart: function (event) {
                            var box = event.target.parentElement;
                            var rect = box.getBoundingClientRect();

                            // store the center as the element has css `transform-origin: center center`
                            box.setAttribute('data-center-x', rect.left + rect.width / 2);
                            box.setAttribute('data-center-y', rect.top + rect.height / 2);
                            // get the angle of the element when the drag starts

                            box.setAttribute('data-angle', getDragAngle(event));
                        },
                        onmove: function (event) {
                            var box = event.target.parentElement;

                            var pos = {
                                x: parseFloat(box.getAttribute('data-x')) || 0,
                                y: parseFloat(box.getAttribute('data-y')) || 0
                            };

                            var angle = getDragAngle(event);

                            box.parentElement.querySelector('img').style.transform = 'translate(0,0) rotate(' + angle + 'rad' + ')';
                        },
                        onend: function (event) {
                            var box = event.target.parentElement;

                            // save the angle on dragend
                            box.parentElement.setAttribute('data-angle', _this.getDragAngle(event));
                            box.parentElement.setAttribute('data-rotation', _this.radians_to_degrees(_this.getDragAngle(event)));
                            _this.saveCustomization();
                        },

                    });
                } else {
                    $('.ui-rotatable-handle').hide();
                    if ($(this.selectedItem).children('.ui-rotatable-handle').length) {
                        $(this.selectedItem).children('.ui-rotatable-handle').show();
                    } else {
                        let _this = this;
                        $(_this.selectedItem).rotatable({
                            degrees: parseInt($(this).attr('data-rotation')),
                            rotate: function (e, ui) {
                                $(this).attr(
                                    "data-rotation",
                                    parseInt((parseFloat(ui.angle.current) * 180) / Math.PI)
                                );
                            },
                            stop: function() {
                                _this.saveCustomization();
                            }
                        });
                    }
                }
            },

            getRotationDegrees: function (obj) {
                var matrix = obj.css("-webkit-transform") ||
                    obj.css("-moz-transform") ||
                    obj.css("-ms-transform") ||
                    obj.css("-o-transform") ||
                    obj.css("transform");
                if (matrix !== 'none') {
                    var values = matrix.split('(')[1].split(')')[0].split(',');
                    var a = values[0];
                    var b = values[1];
                    var angle = Math.round(Math.atan2(b, a) * (180 / Math.PI));
                } else {
                    var angle = 0;
                }
                return (angle < 0) ? angle + 360 : angle;
            },

            getDragAngle: function (event) {
                var box = event.target.parentElement;
                var startAngle = parseFloat(box.getAttribute('data-angle')) || 0;
                var center = {
                    x: parseFloat(box.getAttribute('data-center-x')) || 0,
                    y: parseFloat(box.getAttribute('data-center-y')) || 0
                };
                var angle = Math.atan2(center.y - event.clientY,
                    center.x - event.clientX);

                return angle - startAngle;
            },

            radians_to_degrees: function (radians) {
                var pi = Math.PI;
                var degrees = radians * (180 / pi);
                if (degrees < 0) {
                    degrees += 360;
                }
                return parseInt(degrees);
            },

            resetCustomization: function() {
                $('.picture_container .addProducts').remove();
                this.saveCustomization();
            },

            onAddText: function() {
                this.initDraggableItems();
                this.saveCustomization();
            },

            loadConfiguration: function(product_configurator_id) {
                let _this = this;
                let config = _this.getConfigurationFromDatabase(product_configurator_id);
                if (config.steps.length) {
                    setTimeout(function() {_this.applyConfiguration(config);},700);
                }
            },

            getConfigurationFromDatabase: function(product_configurator_id) {
                let response = $.ajax({
                    url: path_relative_root  + "ajax_configurator_get_configuration.php",
                    async: false,
                    type: "post",
                    dataType: 'json',
                    data: {
                        pc_id: product_configurator_id
                    },success: function(res) {
                        if (res.success) {
                            return res;
                        } else {
                            alertV2(res.error);
                        }
                    }
                });

                if(response.responseJSON.success) {
                    return response.responseJSON.json;
                }
            },

            applyConfiguration: function(allConfig) {
                let _this = this;

                // get product picture data
                // because hidden pictures have no width and height
                let product_picture_size = {};
                let product_picture_visible = $('.picture_container:visible img.ui-droppable:first');
                product_picture_size.width = parseFloat(product_picture_visible.width());
                product_picture_size.height = parseFloat(product_picture_visible.height());

                let configuratorFonts = _this.configuratorStore.state.configurator.option_broderie.config.fonts;

                $.each(allConfig.steps, function(index, config) {
                    let picture = $('.picture_container img[src="'+config.picture.src+'"]');
                    if (!picture.length) {
                        picture = $('.picture_container img[alt="'+config.letter+'"]');
                    }
                    let picture_container = picture.parent();
                    $.each(config.customization, function(index2, item) {
                        let placed_in_percentage = false;
                        let item_object = false;
                        if (item.picture !== undefined) {
                            if (isMobile) {
                                item_object = $('<div class="addProducts cloned"></div>');
                            } else {
                                item_object = $('<div class="addProducts ui-draggable cloned"></div>');
                            }
                            item_object.html('<img src="' + item.picture.src + '" alt="' + item.title + '" />');

                            if (isMobile) {
                                item_object.append('<div class="buttons_container"><span class="rotation-handle"></span><span class="remove_customization"></span></div>');
                            } else {
                                item_object.append('<span class="remove_customization"></span>');
                            }

                            item_object.find('.remove_customization').on('click', function(){
                                isMobile ? $(this).parents('.addProducts.cloned').remove() : $(this).parent('.addProducts.cloned').remove();
                                _this.hideZoomItem();
                                _this.saveCustomization();
                            });
                        } else {
                            let fontSize = 0;
                            let selectedFont = configuratorFonts.find(font => font.name == item.text.font_name);
                            if (selectedFont && item.text.size_number) {
                                let sizeName = 'size_'+item.text.size_number;
                                fontSize = selectedFont.sizes.desktop[sizeName];
                            }

                            item_object = $('<div class="addProducts ui-draggable cloned addTexts" customization-font="'+item.text.font+'" customization-font-name="'+item.text.font_name+'" customization-size="'+fontSize+'" customization-size-number="'+item.text.size_number+'" customization-color="'+item.text.color+'" customization-color-name="'+item.text.color_name+'"><div style="position:absolute">'+item.text.value+'</div></div>');
                            item_object.css('font-family',item.text.font);
                            item_object.css('font-size',fontSize+'px');
                            item_object.css('color',item.text.color);
                        }

                        item_object.attr('step_id',config.step);
                        item_object.attr('product_id',item.product_id);
                        item_object.attr('color_id',item.color_id);
                        item_object.attr('size_id',item.size_id);
                        item_object.css('position','absolute');

                        if (item.position.x_percentage!=undefined) {
                            let position = {};
                            position.x = parseInt(parseFloat(item.position.x_percentage)*product_picture_size.width/100);
                            position.y = parseInt(parseFloat(item.position.y_percentage)*product_picture_size.height/100);


                            if (isMobile) {
                                item_object.css('transform', 'translate(' + position.x + 'px,' + position.y + 'px)');
                                item_object.css('top', 0);
                                item_object.css('left', 0);
                                item_object.attr('data-translatex', position.x);
                                item_object.attr('data-translatey', position.y);
                            } else {
                                item_object.css('left',position.x+'px');
                                item_object.css('top',position.y+'px');
                            }
                        }
                        else {
                            item_object.css('left',item.position.x+'px');
                            item_object.css('top',item.position.y+'px');


                            if (isMobile) {
                                item_object.css('transform', 'translate(' + item.position.x + 'px,' + item.position.y + 'px)');
                                item_object.attr('data-translatex', item.position.x);
                                item_object.attr('data-translatey', item.position.y);
                            } else {
                                item_object.css('left',item.position.x+'px');
                                item_object.css('top',item.position.y+'px');
                            }
                        }


                        if (item.picture !== undefined) {

                            if (item.picture.width_percentage != undefined && item.picture.width_percentage != 0) {
                                item_object.css('width', item.picture.width_percentage+'%');
                            } else {
                                if (item.picture.width != undefined && item.picture.width != 0) {
                                    item_object.css('width', item.picture.width + 'px');
                                }
                            }

                            if (item.picture.height_percentage != undefined && item.picture.height_percentage != 0) {
                                item_object.css('height', item.picture.height_percentage+'%');
                            } else {
                                item_object.css('height', item.picture.height + 'px');
                            }
                        }

                        //il faut bien setter le width et le height avant rotation

                        item_object.attr('data-width',item.picture.width);
                        item_object.attr('data-height',item.picture.height);

                        if (isMobile) {
                            item_object.find('>img').rotate(item.rotation);
                            item_object.attr('data-x',parseFloat(item_object.attr('data-translatex')).toFixed(2));
                            item_object.attr('data-y',parseFloat(item_object.attr('data-translatey')).toFixed(2));
                        } else {
                            item_object.rotate(item.rotation);
                            item_object.attr('data-x',parseFloat(item_object.css('left')).toFixed(2));
                            item_object.attr('data-y',parseFloat(item_object.css('top')).toFixed(2));
                        }
                        item_object.attr('data-rotation',item.rotation);

                        picture_container.append(item_object);
                    });
                });
                this.initDraggableItems();
                this.initDroppableItems();
                this.saveCustomization();
            },

            alternateStep: function() {
                if (this.configuratorStore.getStep()=="2") {
                    this.configuratorStore.setStep("3");
                } else {
                    this.configuratorStore.setStep("2");
                }
            },

            toggleDescription : function() {
                if (document.querySelector('.configurator_product_description').classList.contains('opened')) {
                    this.hideDescription();
                } else {
                    this.displayDescription();
                }
            },

            displayDescription: function() {
                if (!$('.configurator_product_description.opened').length) {
                    $('.configurator_product_description').addClass('opened');
                    $('.configurator_product_description.opened').fadeIn(300);
                }
            },

            hideDescription: function() {
                if ($('.configurator_product_description.opened').length) {
                    $('.configurator_product_description.opened').fadeOut(300);
                    $('.configurator_product_description.opened').removeClass('opened');
                }
            },
/*
            removeCustom : function() {
                let _this = this;
                $(ui.helper).remove();
                $(ui.draggable).remove();
                _this.saveCustomization();
            }, */

            displayZoomItem: function(item) {
                let src = item.children('img:first').attr('src');
                this.zoomContent = '<img src="'+src+'" alt="patch" />';
                this.displayZoom = true;
            },

            hideZoomItem: function() {
                this.displayZoom = false;
            }
        }
    }

}());
