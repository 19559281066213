let wConfiguratorSearchProducts = (function () {
    let template = `
        <section class="configurator_search_products">
            <div class="search_steps" :class="[{active: (currentStep == 1)}, 'step_1']">
                <div class="setupConfig_nav">
                    <span class="current_step">1/5</span>
                </div>
                <div class="title setupConfig_title">
                    <span class="uppercase step_subtitle">{{ 'setupConfig1_subtitle' | translate }}</span>
                    <label class="uppercase step_title">{{ 'setupConfig1_title' | translate }}</label>
                </div>
                <div class="setupConfig_progress"><span class="setupConfig_progress_loaded"></span></div>
                <div class="content setupConfig_content" v-show="currentStep==1">
                    <div id="subcategories_container">
                        <div class="subcategories" v-for="subcategory in subcategories">
                            <label class="picture_container" :for="'subcategory_id'+subcategory.id">
                                <img :src="subcategory.image" :alt="subcategory.name" />
                            </label>
                            <label class="w-input">
                                <input type="radio" name="subcategory_id" :id="'subcategory_id'+subcategory.id" class="w-input-element" :value="subcategory.id" v-model="selectedFilters.sscategorie_id" :data-nicename="subcategory.name" @change="selectFilter" />
                                <p class="button primary" :for="'subcategory_id'+subcategory.id">
                                    <span v-if="(subcategory.id == 38) || (subcategory.id == 165) || (subcategory.id == 168)">${Translator.translate( isMobile ? 'configurator_personnalize_cabas_mobile' : 'configurator_personnalize_cabas')}</span>
                                    <span v-else>${Translator.translate( isMobile ? 'configurator_personnalize_pochette_mobile' : 'configurator_personnalize_pochette')}</span>
                                </p>
                            </label>
                        </div>
                    </div>
                </div>
                <button 
                    class="validate_buttons" 
                    @click="validateFilterProducts(
                        event, 
                        {
                            'image' : getSelectedSubcatImg(selectedFilters.sscategorie_id), 
                            'step_name' : '${Translator.translate('configurator_recap_step1')}', 
                            'step_value' : getSelectedSubcatName(selectedFilters.sscategorie_id), 
                            'step' : currentStep
                        }
                    )"
                >{{ 'validate' | translate}}</button>
            </div>
            <div class="search_steps" :class="[{active: (currentStep == keyCharateristic+2)}, 'step_' + (keyCharateristic+2)]" v-for="characteristicName, keyCharateristic in selectedCharacteristicsFilters">
                <div class="setupConfig_nav">
                    <span class="step_back" v-if="selectedFilters.sscategorie_id == 40" @click="setStep(1)">${Translator.translate('back')}</span>
                    <span class="step_back" v-else @click="setStep(keyCharateristic+1)">${Translator.translate('back')}</span>
                    <span class="current_step">{{keyCharateristic+2}}/5</span>
                </div>
                <div class="title setupConfig_title">
                    <label class="uppercase step_title" v-if="keyCharateristic+2 == 2">${Translator.translate('configurator_select_product_size')}</label>
                    <label class="uppercase step_title" v-if="keyCharateristic+2 == 3">${Translator.translate('configurator_select_product_matter')}</label>
                </div>
                <div class="setupConfig_progress"><span class="setupConfig_progress_loaded"></span></div>


                <div class="content setupConfig_content" v-show="currentStep == (keyCharateristic+2)">


                    <div v-if="characteristicName=='taille' || characteristicName=='size'" id="sizes_container">
                        <label
                            class="w-input sizes"
                            :class="(selectedFilters[characteristicName]==value.name?'active':'')"
                            v-for="value, key_value in characteristicFilters[characteristicName]"
                        >
                            <input 
                                type="radio" 
                                :name="'characteristic_'+characteristicName" 
                                :id="'characteristic'+characteristicName+'_'+key_value" 
                                class="w-input-element" 
                                :value="value.name" 
                                v-model="selectedFilters[characteristicName]" 
                                @change="selectFilter" 
                            />
                            <p :id="'characteristic'+characteristicName+'_'+key_value" class="size_wrapper">
                                <img class="" :src="'${path_relative_root}img/configurator/sizes/size_' + (key_value+1) + '.jpg'" />
                                <span class="size_name">{{ value.name }}</span>
                            </p>
                            <div v-if="canDisplayZipOptions(value.name)" class="optional_steps">
                                <label class="w-input optional_step" v-for="zip, zipKey in zipOptions">
                                    <input 
                                        type="radio" 
                                        name="zip" 
                                        :id="'zip_'+zipKey" 
                                        class="w-input-element" 
                                        :value="zip.value" 
                                        v-model="selectedFilters.zip" 
                                        @change="selectFilter" 
                                        :checked="(zipKey == 'yes')" 
                                    />
                                    <p class="step_name" :for="'zip_'+zipKey">
                                        <span>{{ zip.label }}</span>
                                    </p>
                                </label>
                            </div>
                        </label>
                    </div>


                    <div v-else-if="characteristicName=='matiere' || characteristicName=='fabric'" id="matters_container">
                        <div class="matters" v-for="value, key_value in characteristicFilters[characteristicName]">
                            <input type="radio" :name="'characteristic_'+characteristicName" :id="'characteristic'+characteristicName+'_'+key_value" class="w-input-element" :value="value.name" v-model="selectedFilters[characteristicName]" @change="selectFilter" />
                            <label class="picture_container" :for="'characteristic'+characteristicName+'_'+key_value">
                                <img :src="value.image" :alt="value.name" />
                                <span class="matter_name" :id="'characteristic'+characteristicName+'_'+key_value">{{ value.name }}</span>
                            </label>
                        </div>
                    </div>
                    <div v-else class="characteristics_container">
                        <label class="w-input w-radio-input" v-for="value, key_value in characteristicFilters[characteristicName]">
                            <input type="radio" :name="'characteristic_'+characteristicName" :id="'characteristic'+characteristicName+'_'+key_value" class="w-input-element" :value="value.name" v-model="selectedFilters[characteristicName]" @change="selectFilter" />
                            <p class="w-input-label" :id="'characteristic'+characteristicName+'_'+key_value">
                                <span>{{ value.name }}</span>
                            </p>
                        </label>
                    </div>

                    <div class="info_about_sizes" v-if="characteristicName=='taille' || characteristicName=='size'">
                        <span class="info_label" @click="toggleSizeLightbox">${Translator.translate('info_about_sizes')}</span>
                    </div>
                    <div class="lightbox lightbox_about_sizes_configurator look">
                        <span class="close close_pop" @click="toggleSizeLightbox"></span>

                        <div class="about_sizes_leftPart">
                            <div class="pic_switcher">
                                <span class="switcher" data-tab="capacity" @click="handleLightboxActiveTab">${Translator.translate('configurator_about_sizes_capacity')}</span>
                                <span class="switcher active" data-tab="look" @click="handleLightboxActiveTab">${Translator.translate('configurator_about_sizes_look')}</span>
                            </div>
                            <div class="about_sizes_text_container">
                            <span class="about_sizes_text_subtitle">${Translator.translate('configurator_about_sizes_text_subtitle')}</span>
                                <span class="about_sizes_text_title">${Translator.translate('configurator_about_sizes_text_title')}</span>
                            </div>
                            <div class="sizes_display_container">
                                <label
                                    class="lightbox_size"
                                    :class="[{active : (key_value == 0)}]"
                                    :data-size="key_value+1"
                                    v-for="value, key_value in characteristicFilters[characteristicName]"
                                    @click="handleLightboxActivePic"
                                >
                                    <img class="" :src="'${path_relative_root}img/configurator/sizes/size_' + (key_value+1) + '.jpg'" />
                                    <span class="size_name">{{ value.name }}</span>
                                </label>
                            </div>
                        </div>
                        <div class="about_sizes_rightPart">
                            <div class="lightbox_size_rendered_container">
                                <div
                                    class="lightbox_size_rendered"
                                    :class="{active : (key_value == 0)}" :data-size="key_value+1"
                                    v-for="value, key_value in characteristicFilters[characteristicName]"
                                >
                                    <img class="size_capacity rendered_size_pic" :src="'${path_relative_root}img/configurator/sizes/capacities/${lang}/size_' + (key_value+1) + '.jpg'" />
                                    <img class="size_look rendered_size_pic active" :src="'${path_relative_root}img/configurator/sizes/looks/size_' + (key_value+1) + '.jpg'" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <button 
                    class="validate_buttons button primary" 
                    :class="{disabled : !selectedFilters[characteristicName]}" :disabled="!selectedFilters[characteristicName]" 
                    @click="validateFilterProducts(
                        event, 
                        {
                            'image' : getSelectedCaractImage(characteristicName), 
                            'step_name' : getCaractTranslate(characteristicName), 
                            'step_value' : getSelectedCaractName(characteristicName), 
                            'step' : currentStep
                        }
                    )"
                >
                    ${Translator.translate('configurator_next_step')}
                </button>
                
            </div>

            <div class="search_steps" :class="[{active: (currentStep == (selectedCharacteristicsFilters.length+2))}, 'step_4']">
                <div class="setupConfig_nav">
                    <span class="step_back" @click="setStep(3)">${Translator.translate('back')}</span>
                    <span class="current_step">{{selectedCharacteristicsFilters.length+2}}/5</span>
                </div>
                <div class="title setupConfig_title">
                    <label class="uppercase step_title">{{ 'configurator_select_product_color' | translate }}</label>
                </div>
                <div class="setupConfig_progress"><span class="setupConfig_progress_loaded"></span></div>
                <div class="content setupConfig_content" v-show="currentStep == (selectedCharacteristicsFilters.length+2)">
                    <ul class="colors_list">
                        <li v-for="color in colors" class="colors">
                            <input type="radio" name="color_id" :id="'color_id'+color.id" class="w-input-element" :value="color.id" v-model="selectedFilters.color_id" :data-image="color.product_img.mini" :data-nicename="color.name.toLowerCase()" @change="selectFilter" />
                            <label class="color_visu" :for="'color_id'+color.id" :class="colorActive(color.id)" :style="{'background-image' : 'url(' + color.product_img.largeY + ')'}" :title="color.name"><span class="color_name">{{color.name}}</span></label>
                        </li>
                    </ul>
                </div>
                <button
                    v-if="filteredProducts.length==1"
                    @click="validateFilterProducts(event, {'image' : getSelectedColorImage(selectedFilters.color_id), 'step_name' : '${Translator.translate('configurator_recap_color')}', 'step_value' : getSelectedColorName(selectedFilters.color_id), 'step' : currentStep})"
                    class="validate_buttons button primary"
                >${Translator.translate('configurator_next_step')}</button>
                <button v-else class="validate_buttons button primary" :class="{disabled : !selectedFilters.color_id}" :disabled="!selectedFilters.color_id" @click="alertManyProductsFound">${Translator.translate('configurator_next_step')}</button>
            </div>

            <div class="search_steps step_recap" :class="[{active: (currentStep == 5)}, 'step_5']">
                <section class="recap_left_side">
                    <div class="step_back_recap" @click="setStep(4)">
                        <span>${Translator.translate('back')}</span>
                    </div>
                    <div class="recap_product_swiper swiper-container" v-if="filteredProducts.length && filteredProducts[0].pictures">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="picture in filteredProducts[0].pictures">
                                <img :src="picture">
                            </div>
                        </div>
                        <div class="recap_product_swiper_arrow swiper-button-prev" v-if="filteredProducts.length"></div>
                        <div class="recap_product_swiper_arrow swiper-button-next" v-if="filteredProducts.length"></div>
                    </div>
                </section>
                <section class="recap_right_side">
                    <div class="setupConfig_nav">
                        <span class="current_step">5/5</span>
                    </div>
                    <div class="title setupConfig_title">
                        <label class="uppercase step_title">{{ 'configurator_recap_title' | translate }}</label>
                        <span class="uppercase step_subtitle">{{ 'configurator_recap_subtitle' | translate }}</span>
                    </div>
                    <div class="setupConfig_progress"><span class="setupConfig_progress_loaded"></span></div>
                    <div class="content setupConfig_content" v-show="currentStep == (5)">
                        <div class="recap_container">
                            <div class="recap_step" v-for="step, step_key in recap">
                                <span class="recap_image_container" :style="{'background-image' : 'url(' + step.image + ')'}"><img class="recap_step_image" :src="step.image" /></span>
                                <span class="recap_step_desc">{{step.step_name}} {{step.step_value}}</span>
                                <span class="recap_step_modify" @click="setStep(step_key)">${Translator.translate('modify')}</span>
                            </div>
                        </div>
                        <span class="recap_disclaimer">${Translator.translate('configurator_recap_disclaimer')}</span>

                        <button v-if="filteredProducts.length==1" class="button secondary recap_to_bask" :class="{'loading': isLoadingAddBasket}" @click="addProductToBasket">{{ 'add_to_basket' | translate }}
                            <span class="recap_price" v-if="this.configuratorStore.state.filteredProducts"  v-html="formatPrice(configuratorStore.state.filteredProducts[0].price)"></span>
                        </button>

                        <button
                            v-if="filteredProducts.length==1"
                            @click="validateSearchProducts"
                            class="validate_buttons button primary"
                        >${Translator.translate('configurator_start_customization')}</button>
                        <button v-else class="validate_buttons button primary" :class="{disabled : !selectedFilters.color_id}" :disabled="!selectedFilters.color_id" @click="alertManyProductsFound">${Translator.translate('configurator_start_customization')}</button>
                    </div>
                </section>
            </div>

        </section>
    `;

    return {
        name: 'wConfiguratorSearchProducts',
        template: template,
        components: {},
        props: {
            products : {
                type: Array
            },
            filteredProducts: {
                type: Array
            },
            categoryId: {
                type: Number,
                default: 0
            },
            langId: {
                type: Number,
                default: 1
            },
            recapFilteredProduct : {},
        },
        filters: {
            translate: Translator.translate,
        },
        data() {
            return {
                pictures: [],
                swiper : null,
                selectedFilters: {},
                selectedCharacteristicsFilters: [],
                characteristicFilters: {},
                subcategories: [],
                subcategoriesOptions: [],
                colors: [],
                sizes: [],
                sizesWithZipOptions: ['Moyen +', 'Medium +', 'L porté épaule', 'Shoulder strap L'],
                zipOptions: {
                    yes: {
                        value: 'oui',
                        label: Translator.translate('avec_zip')
                    },
                    no: {
                        value: 'non',
                        label: Translator.translate('sans_zip')
                    }
                },
                recap: {},
                currentStep: 1,
                maxSteps: 0,
                stepsFilters: [],
                configuratorStore: configuratorStore,
                subCategoryName: '',
                sizeLabel: 'taille',
                isLoadingAddBasket: false,
                isMobile: isMobile
            }
        },
        watch: {
            products: function () {
                this.initFilters();
            },
            currentStep: function () {
                this.configuratorStore.state.searchStep = this.currentStep
            },
            "selectedFilters.taille": function () {
                if (this.selectedFilters.zip != undefined) {
                    this.selectedFilters.zip = '';
                    this.filterProducts();
                }
            },
            "configuratorStore.state.subcategoryId": function () {
                if (this.configuratorStore.state.subcategoryId) {
                    this.selectedFilters.sscategorie_id = this.configuratorStore.state.subcategoryId;
                }
            }
        },
        computed: {
            selectedSizeName: function () {
                let selectedSizeName = '';
                if (this.selectedFilters.size_id) {
                    let selectedSizeId = this.selectedFilters.size_id;
                    $.each(this.sizes, function (index, size) {
                        if (selectedSizeId == size.id) {
                            selectedSizeName = size.name;
                        }
                    });
                }
                return selectedSizeName;
            },
            selectedColorName: function () {
                let selectedColorName = '';
                if (this.selectedFilters.color_id != undefined && this.selectedFilters.color_id) {
                    let selectedColorId = this.selectedFilters.color_id;
                    $.each(this.colors, function (index, color) {
                        if (selectedColorId == color.id) {
                            selectedColorName = color.name;
                        }
                    });
                }
                return selectedColorName;
            }
        },
        mounted() {
            var _this = this;

            this.initLabels();
            this.initFilters();
            this.setRecapFilteredProduct();

            $(function() {
                if (!isMobile) {
                    if ($('.configuratorV2_1:not(.configurator_configure)').length || $('.configuratorV2_1_product:not(.configurator_configure)').length) {
                        setTimeout(function() {
                            $('html, body').animate({
                                scrollTop: $('.configurator_content').offset().top - 71
                            }, 500);
                        }, 850);
                    }
                }
            })
        },
        methods: {
            initLabels: function () {
                if (this.langId != 1) {
                    this.sizeLabel = 'size';
                }
            },
            setRecapFilteredProduct: function () {
                if (this.recapFilteredProduct !== undefined && this.recapFilteredProduct) {
                    this.currentStep = 5;
                    this.recap = this.recapFilteredProduct;
                    this.selectedFilters = this.configuratorStore.state.selectedFiltersSave;
                    this.filterProducts(false);
                }
            },

            scrollToContainer: function() {
                // Configurator auto scroll on page load
                if ($('.configuratorV2_1:not(.configurator_configure)').length || $('.configuratorV2_1_product:not(.configurator_configure)').length) {
                    $('html, body').animate({
                        scrollTop: $('.configurator_content').offset().top - 71
                    }, 500);
                }
            },

            getSelectedSubcatImg: function(subcat_id) {
                // Function used to return properly checked subcat image
                return document.querySelector('[for="subcategory_id'+ subcat_id +'"] img').src;
            },
            getSelectedSubcatName: function(subcat_id) {
                // Function used to return properly checked subcat name
                return document.querySelector('#subcategory_id'+ subcat_id).dataset.nicename;
            },

            getSelectedCaractImage: function(carac_name) {
                // Function used to return properly checked subcat name
                if (carac_name === 'matiere' || carac_name === 'fabric') {
                    return document.querySelector('[name="characteristic_'+ carac_name + '"]:checked + .picture_container img').src;

                } else {
                    return document.querySelector('[name="characteristic_'+ carac_name + '"]:checked + .size_wrapper img').src;
                }
            },
            getSelectedCaractName: function(carac_name) {
                // Function used to return properly checked subcat name
                let element = document.querySelector('[name="characteristic_'+ carac_name + '"]:checked');
                return element.value;
            },
            getCaractTranslate(carac_name) {
                return Translator.translate('configurator_recap_' + carac_name);
            },

            getSelectedColorImage: function(color_id) {
                // Function used to return properly checked subcat name
                return document.querySelector('#color_id'+ color_id).dataset.image;
            },
            getSelectedColorName: function(color_id) {
                // Function used to return properly checked subcat name
                return document.querySelector('#color_id'+ color_id).dataset.nicename;
            },

            setCharacteristicFilter: function (characteristicName, value) {
                this.selectedFilters[characteristicName] = value;
            },

            canDisplayZipOptions: function (size) {
                let sizesWithZipOptions = this.sizesWithZipOptions;
                let selectedSize = this.selectedFilters[this.sizeLabel];
                let match = false;
                $.each(sizesWithZipOptions, function (key, value) {
                    if ($.trim(value) == $.trim(size) && $.trim(selectedSize) == $.trim(value)) {
                        match = true;
                    }
                });
                return match;
            },

            initSwiper: function() {
                setTimeout(function() {
                    this.swiper = new Swiper($('.recap_product_swiper'), {
                        slidesPerView: 1,
                        centeredSlides: true,
                        navigation: {
                            nextEl: '.recap_product_swiper .swiper-button-next',
                            prevEl: '.recap_product_swiper .swiper-button-prev',
                        },
                    });
                }, 500);
            },

            initFilters: function (productsList) {
                this.initSubcategories();

                //on part des produits filtrés pour récupérés les caractéristiques voulues en ajax
                this.initCharacteristicFilters();

                //on part des produits filtrés pour récup les couleurs et les tailles
                let _this = this;
                let products = _this.products;
                if (productsList) {
                    products = productsList;
                }

                // reset filters
                _this.colors = [];
                _this.sizes = [];
                $.each(_this.selectedCharacteristicsFilters, function (index, value) {
                    _this.characteristicFilters[value] = [];
                });

                if (products.length) {
                    $.each(products, function (index, product) {
                        $.each(product, function (productIndex, productValue) {
                            switch (productIndex) {
                                case 'colors':
                                    $.each(productValue, function (attributeIndex, attribute) {
                                        let exists = _this.colors.find(function (value) {
                                            return attribute.name.toLowerCase() == value.name.toLowerCase();
                                        });

                                        if (exists === undefined) {
                                            _this.colors.push(attribute);
                                        }
                                    });
                                    break;
                                case 'sizes':
                                    $.each(productValue, function (attributeIndex, attribute) {
                                        let exists = _this.sizes.find(function (value) {
                                            return attribute.name.toLowerCase() == value.name.toLowerCase();
                                        });

                                        if (exists === undefined) {
                                            _this.sizes.push(attribute);
                                        }
                                    });
                                    break;

                                case 'characteristics':
                                    let productCharacteristics = productValue;
                                    $.each(_this.selectedCharacteristicsFilters, function (indexCharacteristicLabel, characteristicLabel) {
                                        $.each(productCharacteristics, function (indexProductCharacteristic, productCharacteristic) {
                                            if (productCharacteristic.label_noaccent == characteristicLabel) {
                                                let characteristicFilters = _this.characteristicFilters[characteristicLabel];
                                                let exists = characteristicFilters.find(function (value) {
                                                    return productCharacteristic.value.toLowerCase() == value.name.toLowerCase();
                                                });

                                                if (exists === undefined) {
                                                    let item = {};
                                                    item.id = productCharacteristic.id;
                                                    item.name = productCharacteristic.value;
                                                    if (characteristicLabel == 'matiere' || characteristicLabel == 'fabric') {
                                                        let filename = $.trim(RemoveAccents(productCharacteristic.value));
                                                        item.image = path_relative_root + 'img/configurator/matters/' + filename.toLowerCase() + '.jpg';
                                                    }
                                                    _this.characteristicFilters[characteristicLabel].push(item);
                                                }
                                            }
                                        });
                                        _this.characteristicFilters[characteristicLabel].sort(_this.compare);
                                    });
                                    break;
                            }
                        });
                    });

                    let arraySortedCharacteristic = [];
                    $.each(_this.configuratorStore.sizesOrder[_this.langId], function (index, sizeName) {
                        $.each(_this.characteristicFilters[_this.sizeLabel], function (indexCharact, charact) {
                            if (charact.name.toLowerCase() == sizeName.toLowerCase()) {
                                arraySortedCharacteristic.push(charact);
                            }
                        });
                    });
                    _this.characteristicFilters[_this.sizeLabel] = arraySortedCharacteristic;

                    _this.selectFilter();
                }
            },

            initCharacteristicFilters: function () {
                let _this = this;
                if (!_this.selectedCharacteristicsFilters.length) {
                    $.ajax({
                        url: path_relative_root + 'ajax_configurator_load_characteristic_filters.php',
                        type: 'post',
                        async: false,
                        dataType: 'json',
                        success: function (response) {
                            _this.selectedCharacteristicsFilters = response;

                            //init for charateristics filters && steps
                            _this.stepsFilters[1] = 'sscategorie_id';
                            $.each(_this.selectedCharacteristicsFilters, function (index, value) {
                                _this.characteristicFilters[value] = [];
                                _this.stepsFilters[(index + 2)] = $.trim(value);
                            });
                            _this.stepsFilters[_this.selectedCharacteristicsFilters.length + 2] = 'color_id';

                        }
                    });
                }
            },

            initSubcategories: function () {
                if (!this.subcategories.length) {
                    $.ajax({
                        url: path_relative_root + 'ajax_configurator_load_subcategories.php',
                        type: 'post',
                        data: {
                            category_id: this.categoryId
                        },
                        dataType: 'json',
                        success: this.handleInitSubcategories
                    });
                }
            },

            handleInitSubcategories: function (subcategories) {
                this.subcategories = subcategories;
            },

            toggleSizeLightbox: function() {
                $('.lightbox_about_sizes_configurator').hasClass('active') ? $('.lightbox_about_sizes_configurator').removeClass('active') : $('.lightbox_about_sizes_configurator').addClass('active');
            },

            handleLightboxActiveTab: function(event) {
                let target = $(event.target);
                let type = target.attr('data-tab');
                let to_deactivate = (type == 'look' ? 'capacity' : 'look');

                // Add correct class to lightbox
                $('.lightbox_about_sizes_configurator').removeClass(to_deactivate);
                $('.lightbox_about_sizes_configurator').addClass(type);

                // Handle active tab switcher
                $('.switcher[data-tab="'+ to_deactivate +'"]').removeClass('active');
                $('.switcher[data-tab="'+ type +'"]').addClass('active');

                // Activate correct pictures in rightPart
                $('.lightbox_about_sizes_configurator .about_sizes_rightPart .size_' + to_deactivate).removeClass('active');
                $('.lightbox_about_sizes_configurator .about_sizes_rightPart .size_' + type).addClass('active');
            },

            handleLightboxActivePic: function(event) {
                let target = $(event.target);
                let size = target.attr('data-size');

                // Add active to selected size
                $('.lightbox_size.active').removeClass('active');
                $('.lightbox_size[data-size="'+ size +'"]').addClass('active');

                // Add active class to matching rightPart pic
                $('.lightbox_size_rendered.active').removeClass('active');
                $('.lightbox_size_rendered[data-size="'+ size +'"]').addClass('active');
            },

            validateFilterProducts(event = null, recap_arg = null) {
                if(!((this.configuratorStore.state.selectedFilters.sscategorie_id == 38) || (this.configuratorStore.state.selectedFilters.sscategorie_id == 165) || (this.configuratorStore.state.selectedFilters.sscategorie_id == 168))) {
                    this.filterProducts(true);
                }
                this.configuratorStore.state.selectedFilters = this.selectedFilters;
                this.configuratorStore.state.selectedFiltersSave = this.selectedFilters;
                this.configuratorStore.state.recap = this.recap;
                this.configuratorStore.state.currentStep = this.currentStep;

                // set recap obj
                if (recap_arg) {
                    this.setRecapObj(recap_arg);
                }

                // Add loading class to step submit
                if (recap_arg.step && (recap_arg.step == 1)) {
                    $(`.search_steps.step_1`).find('input:checked+.button').addClass('loading');
                } else if (recap_arg.step) {
                    $(`.search_steps.step_${recap_arg.step}`).find('.validate_buttons').addClass('loading');
                }

                // Proceed to next step
                this.nextStep();

                // Remove loaders
                if (recap_arg.step && (recap_arg.step == 1)) {
                    $(`.search_steps.step_1`).find('input:checked+.button').removeClass('loading');
                } else if (recap_arg.step) {
                    $(`.search_steps.step_${recap_arg.step}`).find('.validate_buttons').removeClass('loading');
                }
            },

            setRecapObj: function(arg) {
                // Setting recap object (declared in datas) depending on the step

                if (this.currentStep == 1 || this.currentStep == '1') {
                    this.recap['1'] = arg;

                } else if (this.currentStep == 2 || this.currentStep == '2') {
                    this.recap['2'] = arg;

                } else if (this.currentStep == 3 || this.currentStep == '3') {
                    this.recap['3'] = arg;

                } else if (this.currentStep == 4 || this.currentStep == '4') {
                    this.recap['4'] = arg;
                }
            },

            selectFilter: function () {
                this.updateSelectedValue();
                this.filterProducts(false);

                if (this.currentStep == 1) {
                    $('.step_1 .validate_buttons').trigger('click');
                }
            },

            datalayerCustomCabas: function (stepIndex, stepName, eventAction, eventLabel) {
                DataLayerTrigger.CustomCabas(stepIndex, stepName, eventAction, eventLabel)
            },

            updateSelectedValue: function () {
                let selectedSubcategoryName = '';
                if (this.selectedFilters.sscategorie_id) {
                    let selectedSubcategoryId = this.selectedFilters.sscategorie_id;
                    $.each(this.subcategories, function (index, subcategory) {
                        if (selectedSubcategoryId == subcategory.id) {
                            selectedSubcategoryName = subcategory.name;
                        }
                    });
                }
                this.subCategoryName = selectedSubcategoryName;
            },

            filterProducts: function (saveFilters) {
                let _this = this;
                let filteredProducts = [];

                _this.$emit('updateLoader', true);
                $.each(_this.products, function (index, filteredProduct) {
                    let add = true;
                    let productCategories = filteredProduct.categories;
                    let productCharacteristics = filteredProduct.characteristics;

                    $.each(_this.selectedFilters, function (filterName, filterValue) {
                        if (filterValue) {
                            if (filterName == 'sscategorie_id') {
                                let matchCategories = false;
                                $.each(productCategories, function (indexCategory, category) {
                                    if (filterValue == category.sscategorie_id) {
                                        matchCategories = true;
                                    }
                                });
                                if (!matchCategories) {
                                    add = false;
                                }
                            } else {
                                if (filteredProduct[filterName] != undefined) {
                                    if (filteredProduct[filterName].toLowerCase() != filterValue.toLowerCase()) {
                                        add = false;
                                    }
                                } else {
                                    let matchCharacteristic = false;
                                    let hasZipOption = false;
                                    $.each(productCharacteristics, function (indexProductCharacteristic, productCharacteristic) {
                                        if (productCharacteristic.label_noaccent == 'zip') {
                                            hasZipOption = true;
                                        }
                                        if (productCharacteristic.label_noaccent.toLowerCase() == filterName.toLowerCase() && productCharacteristic.value.toLowerCase() == filterValue.toLowerCase()) {
                                            matchCharacteristic = true;
                                        }
                                    });
                                    if (filterName == 'zip' && (filterValue == 'non' || filterValue == 'no') && !matchCharacteristic && !hasZipOption) {
                                        matchCharacteristic = true;
                                    }
                                    if (!matchCharacteristic) {
                                        add = false;
                                    }
                                }
                            }
                        }
                    });

                    if (add) {
                        filteredProducts.push(filteredProduct)
                    }
                });

                if (saveFilters) {
                    _this.initFilters(filteredProducts);
                    _this.configuratorStore.state.filteredProducts = filteredProducts;
                }

                _this.configuratorStore.state.selectedFilters = _this.selectedFilters;
                _this.$emit('updateFilteredProducts', filteredProducts);
                _this.$emit('updateLoader', false);
            },

            validateSearchProducts: function () {
                $('.step_recap.button').addClass('loading'); //loading class on step recap
                this.$emit('validateSearchProducts', true);
                $('.step_recap.button').removeClass('loading'); //loading class on step recap
            },

            colorActive: function (colorId) {
                if (colorId == this.selectedFilters.color_id) {
                    return 'active';
                }
                return '';
            },


            nextStep: function () {
                let checkStep = this.checkStep(this.currentStep);

                if (checkStep.success) {
                    if (this.currentStep == 1) {
                        this.datalayerCustomCabas(this.currentStep, 'select type', 'click', this.subcategories[this.selectedFilters.sscategorie_id].name);

                    } else if (this.currentStep == 2) {
                        this.datalayerCustomCabas(this.currentStep, 'select size', 'click', this.selectedFilters[this.sizeLabel]);

                    } else if (this.currentStep == 3) {
                        this.datalayerCustomCabas(this.currentStep, 'select fabric', 'click', this.selectedFilters['matiere']);
                    }

                    //if no sizes, skip step 2
                    if (this.currentStep === 1 && !this.characteristicFilters[this.sizeLabel].length) {
                        this.currentStep += 2;

                    } else {
                        this.currentStep++;

                        if (this.currentStep === 5) {
                            document.querySelector('body').classList.add('noScroll');

                            // Init selected product swiper
                            this.initSwiper();
                        } else {
                            if (document.querySelector('body').classList.contains('noScroll')) {
                                document.querySelector('body').classList.remove('noScroll');
                            }
                        }
                    }

                    this.filterProducts(true);
                } else {
                    alertV2(checkStep.message);
                }
            },

            setStep: function (step, disableCheck) {
                let _this = this;

                //check currentStep
                if (!disableCheck && step > this.currentStep) {
                    let checkStep = this.checkStep(this.currentStep);
                    if (!checkStep.success) {
                        alertV2(checkStep.message);
                        return;
                    }
                }

                // remove selected filters after this step
                $.each(_this.stepsFilters, function (index, value) {
                    if (index >= step) {
                        _this.selectedFilters[value] = '';
                    }

                });

                //special for Zip
                if (step == 1 || step == 2) {
                    _this.selectedFilters.zip = '';
                }

                // special case when going back to step 1, remove size filter
                if (step == 1) {
                    _this.characteristicFilters = [];
                    this.characteristicFilters = [];
                }
                _this.filterProducts(true);

                //change step
                _this.currentStep = step;

                // If body contains noScroll class and if step is not the recap step
                if (step !== 5) {
                    if (document.querySelector('body').classList.contains('noScroll')) {
                        document.querySelector('body').classList.remove('noScroll');
                    }
                }
            },

            checkStep: function (step) {
                let stepFilter = this.stepsFilters[step];

                //check if waited filter is selected
                if (!this.selectedFilters[stepFilter]) {
                    return {
                        success: false,
                        message: Translator.translate('please_select_option')
                    };
                }

                if (step === 2 && this.canDisplayZipOptions(this.selectedFilters[stepFilter]) && (this.selectedFilters['zip'] == "" || this.selectedFilters['zip'] == undefined)) {
                    let sscategorie_id_selected = this.selectedFilters['sscategorie_id'];
                    let size_selected = this.selectedFilters[this.sizeLabel];
                    // this.setStep(1);
                    this.selectedFilters.sscategorie_id = sscategorie_id_selected;
                    // this.validateFilterProducts();
                    this.selectedFilters[this.sizeLabel] = size_selected;
                    return {
                        success: false,
                        message: Translator.translate('please_select_zip_option')
                    };
                }

                return {
                    success: true
                };
            },

            compare: function (a, b) {
                // Use toUpperCase() to ignore character casing
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();

                let comparison = 0;
                if (nameA > nameB) {
                    comparison = 1;
                } else if (nameA < nameB) {
                    comparison = -1;
                }
                return comparison;
            },

            alertManyProductsFound: function () {
                alertV2(Translator.translate('please_select_color'));
            },

            // Permet de rajouter le produit au panier
            addProductToBasket: function () {
                let _this = this;
                _this.isLoadingAddBasket = true;
                let product = _this.configuratorStore.state.filteredProducts[0];
                product.configurator_product = false;
                product.reinit_basket_virtuel_parent_id = true;

                //send main product to get virtual basket id in session
                let response = wShop.$refs.wConfigurator.sendToBasket(product);

                if (response.error !== undefined && response.error !== 0) {
                    if (response.error.msg !== '' && response.error.msg !== undefined) {
                        alertV2(response.error.msg);
                        _this.isLoadingAddBasket = false;
                    }
                } else {
                    wShop.$refs.wConfigurator.updateSmallBasket();
                }

                // Add loading class to add to basket button
                if ($('.recap_to_bask').hasClass('loading')) {
                    $('.recap_to_bask').removeClass('loading')
                }
            },

            formatPrice(amount) {
                return formatPrice(amount);
            },
        }
    }

}());