let wConfiguratorCustomizationAddText = (function() {
    let template = `
        <section class="configurator_customization_add_text" :id="'component_'+componentId">
            <template v-if="isMobile">
                <div class="title_container broderie">
                    <span class="configurator_separator">-</span>
                    <div class="customization_title" data-range="broderie" @click="handleActiveCustomization">
                        <span>{{ 'broderie' | translate }}</span>
                    </div>
                </div>
            </template>

            <template v-else>
                <span class="configurator_separator">-</span>
                <div class="customization_title" data-range="broderie" @click="handleActiveCustomization">
                    <span>{{ 'broderie' | translate }}</span>
                </div>
            </template>


            <div class="content customize_content" data-range="broderie">
                <div class="content_container">
                    <div class="fonts_container inner_container">
                        <span v-if="isMobile" class="inner_container_title" @click="collapseInnerContainer">${Translator.translate('configurator_select_font')}</span>
                        <span v-else class="inner_container_title">${Translator.translate('configurator_select_font')}</span>
                        <ul class="fonts" :class="['fonts_'+componentId, {collapse_content: isMobile}]">
                            <li
                                :id="'font_'+key"
                                v-for="font,key in fonts"
                                :style="'font-family:'+font.name+';text-transform:'+font.transform"
                                @click="selectFont(font, key)"
                            >
                                abc
                                <span class="font_indicator" v-if="key == 0">{{'configurator_sans_serif' | translate}}</span>
                                <span class="font_indicator" v-else>{{'configurator_serif' | translate}}</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="content_container">
                    <div class="size_container inner_container">
                        <span v-if="isMobile" class="inner_container_title" @click="collapseInnerContainer">${Translator.translate('configurator_select_size')}</span>
                        <span v-else class="inner_container_title">${Translator.translate('configurator_select_size')}</span>
                        <ul class="sizes" :class="['sizes_'+componentId, {collapse_content: isMobile}]">
                            <li
                                :id="'size_'+size"
                                v-for="size in sizes"
                                @click="selectSize(size)"
                                >
                                {{ "font_size_"+size | translate }}
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="content_container">
                    <div class="colors_container inner_container">
                        <span v-if="isMobile" class="inner_container_title" @click="collapseInnerContainer">${Translator.translate('configurator_select_color')}</span>
                        <span v-else class="inner_container_title">${Translator.translate('configurator_select_color')}</span>
                        <ul class="colors_list" :class="{collapse_content: isMobile}">
                            <li v-for="color, key in colors" class="colors" :class="'colors_'+componentId">
                                <input type="radio" name="color_id" :id="'color_id_'+componentId+'_'+key" class="w-input-element" :value="color.code" @click="selectColor(color)"  />
                                <label class="color_label" :for="'color_id_'+componentId+'_'+key" :style="'background-color:'+color.code"  :title="color.name"></label>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="content_container">
                    <div class="text_container inner_container">
                        <span v-if="isMobile" class="inner_container_title" @click="collapseInnerContainer">${Translator.translate('configurator_add_text')}</span>
                        <span v-else class="inner_container_title">${Translator.translate('configurator_add_text')}</span>
                        <template v-if="isMobile">
                            <div class="collapse_content">
                                <input type="text" placeholder="${Translator.translate('configurator_add_text_placeholder')}" v-model="customizedText.value" :maxlength="maxlength" @input="refreshTextPreview()" />
                                <span class="inner_container_disclaimer"> {{ 'add_until_x_characters' | translate }} {{ maxlength }} {{ 'caractere' | translate }}</span>
                            </div>
                        </template>
                        <template v-else>
                            <input type="text" :placeholder="'${Translator.translate('configurator_add_text_placeholder')} ' + '${Translator.translate('add_until_x_characters')} ' + maxlength+ ' ${Translator.translate('caractere')}'" v-model="customizedText.value" :maxlength="maxlength" @keyup="refreshTextPreview()" />
                        </template>
                    </div>
                </div>

                <button class="validate_buttons"  @click="validateStep(step.id)">{{ 'validate' | translate}}</button>
            </div>
        </section>
    `;

    return {
        name: 'wConfiguratorCustomizationAddText',
        template: template,
        components: {
        },
        props: {
            configurator: {type: Object, default: null},
            step: {type: Object},
            selectedCustomizationStep: {type: String, default: ''}
        },
        filters: {
            translate: Translator.translate,
        },
        data() {
            return {
                componentId: null,
                maxlength: 0,
                fonts: [],
                colors: {},
                sizes: {},
                customizedText: {},
                configuratorStore: configuratorStore,
                getSelectedCustomizationStep: '',
                isMobile: isMobile
            }
        },
        watch: {
            'customizedText.value': function() {
                this.checkTextPattern();
            },
        },
        mounted() {
            this.componentId = this._uid;
            this.init();
            this.initSelectedCustomizationStep();

            if (this.isMobile) {
                let title = $('.configurator_customization_add_text .title_container');
                title.remove();
                title.appendTo('.configurator_configure  .titles_container');

                let content = $('.configurator_customization_add_text .customize_content');
                content.remove();
                content.appendTo('.configurator_configure  .contents_container');
            }
        },
        methods: {
            init: function() {
                this.initFonts();
                this.initFontSizes();
                this.initColors();
            },

            initSelectedCustomizationStep: function() {
                this.getSelectedCustomizationStep = this.selectedCustomizationStep;
            },

            initFonts: function() {
                let _this = this;
                if (_this.configurator.option_broderie.config.fonts.length) {
                    $.each(_this.configurator.option_broderie.config.fonts, function (key, font) {
                        _this.fonts.push(font);
                        if (_this.maxlength < font.maxlength) {
                            _this.maxlength = font.maxlength;
                        }
                    });
                }
            },

            selectFont: function(font, key) {
                this.customizedText.font = font;
                $('.fonts_'+this.componentId+' li').removeClass('active');
                $('.fonts_'+this.componentId+' li#font_'+key).addClass('active');
                this.refreshTextPreview();
            },

            initFontSizes: function() {
                this.sizes = [1,2,3];
                let _this = this;

                let customizedProduct = _this.configuratorStore.state.customizedProduct;

                //for small cabas, only two sizes for embroidery
                if (customizedProduct.sizeCharacteristic == 'Petit' || customizedProduct.sizeCharacteristic == 'Small') {
                    $.each(customizedProduct.subCategoriesIds, function(key, id) {
                        //subcategorie_id == Cabas
                        if (id == 38) {
                            _this.sizes = [1,2];
                        }
                    });
                }
            },

            collapseInnerContainer: function(event) {
                let el = $(event.target);
                let container = el.parent('.inner_container');
                let collapsible = container.find('.collapse_content');

                if (!container.hasClass('collapsed')) {
                    collapsible.slideUp();
                    container.addClass('collapsed')
                } else {
                    container.removeClass('collapsed')
                    collapsible.slideDown();
                }
            },

            handleActiveCustomization: function(event) {
                let target_range = $(event.target).closest('.customization_title').attr('data-range');

                if (target_range == 'broderie') {
                    $('.configurator_configure').addClass('broderie_in');
                }

                // Handle active customization TITLE
                if (!$('.customization_title[data-range="' + target_range + '"].active').length) {
                    $('.customization_title.active').removeClass('active');
                    $('.customization_title[data-range="' + target_range + '"]').addClass('active');
                }

                // Handle active customization CONTENT
                if (!$('.customize_content[data-range="' + target_range + '"].active').length) {
                    $('.customize_content.active').removeClass('active');
                    $('.customize_content[data-range="' + target_range + '"]').addClass('active');
                }
            },

            selectSize: function(size) {
                this.customizedText.size = size;
                $('.sizes_'+this.componentId+' li').removeClass('active');
                $('.sizes_'+this.componentId+' li#size_'+size).addClass('active');
                this.refreshTextPreview();
            },

            initColors: function() {
                this.colors = this.configurator.option_broderie.config.colors;
                this.selectColor(this.colors[0]);
            },

            selectColor: function(color) {
                this.customizedText.color = color;
                $('.colors_'+this.componentId).removeClass('active');
                $('.colors_'+this.componentId+' input[name=color_id][value="'+this.customizedText.color.code+'"]').parent().addClass('active');
                this.refreshTextPreview();
            },

            addText: function() {
                let checkText = this.checkText();
                if (!checkText.success) {
                    alertV2(checkText.message);
                }
                else {
                    this.addTextPreview();
                }
            },

            addTextPreview: function() {
                if ($('.picture_container:visible .addTexts').length) {
                    $('.picture_container:visible .addTexts').remove();
                }

                if (
                    this.customizedText.font == undefined
                    || this.customizedText.size == undefined
                    || this.customizedText.color == undefined
                    || this.customizedText.value == undefined
                ) {
                    return true;
                }

                if (this.customizedText.value == '') {
                    return;
                }

                let textParams = this.configuratorStore.getTextCustomizationParams();
                let styles = [
                    'position:absolute',
                    'left:50%',
                    'top:50%',
                    'display:flex',
                    'justify-content:center',
                    'align-items:center'
                ];

                let key_size = 'size_'+this.customizedText.size;
                let size_in_pixels = this.customizedText.font.sizes.desktop[key_size];

                styles.push('font-family:'+this.customizedText.font.name);
                styles.push('font-size:'+size_in_pixels+'px');
                styles.push('color:'+this.customizedText.color.code);

                let text = $('<div class="addProducts addTexts ui-draggable cloned" style="'+styles.join(';')+'" step_id="'+this.step.id+'" product_id="'+this.configurator.option_broderie.products.id+'" color_id="'+this.configurator.option_broderie.products.color_id+'" size_id="'+this.configurator.option_broderie.products.size_id+'" data-rotation="0" customization-font="'+this.customizedText.font.font+'" customization-font-name="'+this.customizedText.font.name+'"  customization-size="'+size_in_pixels+'" customization-size-number="'+this.customizedText.size+'" customization-color="'+this.customizedText.color.code+'" customization-color-name="'+this.customizedText.color.name+'"><div class="text_holder" style="position:absolute; background: url(' + path_relative_root + 'img/couleur/' + this.customizedText.color.id + '.jpg); -webkit-background-clip: text; -webkit-text-fill-color: transparent; background-clip: text; color: transparent; background-size: contain;">'+this.customizedText.value+'</div></div>');
                $('.picture_container:visible').append(text);

                //recenter text
                if (textParams.fixed && textParams.position == 'centered') {
                    let customizedProduct = this.configuratorStore.getCustomizedProduct();
                    // Force the left position in order to always be centered
                    text.css('left', parseFloat($('.configuration_picture').position().left + ($('.configuration_picture').outerWidth() /2)));
                    text.css('top',(customizedProduct.contours.pourcentage.top+5)+'%');
                }

                text.attr('data-x',parseFloat(text.css('left')));
                text.attr('data-y',parseFloat(text.css('top')));

                this.configuratorStore.checkSuperpositions();
                this.$emit('addText');
            },

            refreshTextPreview: function() {
                this.addTextPreview();
            },

            checkText: function() {
                let errors = [];
                if (!this.customizedText.value) {
                    errors.push(this.$options.filters['translate']('customized_text_value_error'));
                }
                if (!this.customizedText.font) {
                    errors.push(this.$options.filters['translate']('customized_text_font_error'));
                }
                if (!this.customizedText.size) {
                    errors.push(this.$options.filters['translate']('customized_text_size_error'));
                }
                if (!this.customizedText.color) {
                    errors.push(this.$options.filters['translate']('customized_text_color_error'));
                }

                //check limit
                /*let checkLimit = this.checkTextLimit();
                if(!checkLimit.success) {
                    errors.push(checkLimit.message);
                }*/

                if (errors.length) {
                    return {success: false, message: errors.join('<br />')};
                }
                return {success: true};
            },

            checkTextPattern: function() {
                let textValue = this.customizedText.value;

                if (!textValue) {
                    return true;
                }

                // s'il n'a pas encore choisi la font, on n'a pas le pattern pour voir si les caractères sont autorisés
                if (this.customizedText.font == undefined) {
                    this.customizedText.value = textValue.substr(0,textValue.length-1);
                    alertV2(this.$options.filters.translate('customized_text_font_error'));
                    return false;
                }

                // on vérifie par rapport au pattern de la font
                let regex = new RegExp(this.customizedText.font.pattern, "gi");
                if (!textValue.match(regex)) {
                    let lastCharacter = textValue.substr(textValue.length-1,1);
                    this.customizedText.value = textValue.substr(0,textValue.length-1);
                    alertV2(lastCharacter+' '+this.$options.filters.translate('customized_text_font_error_pattern')+' '+this.customizedText.font.name);
                    return false;
                }

                return true;
            },

            checkTextLimit: function() {
                let textCustomizationParams = this.configuratorStore.getTextCustomizationParams();

                if(this.getNbTextsOnActiveStep()<textCustomizationParams.limit) {
                    return {success: true};
                }

                return {success: false, message:this.$options.filters.translate('customized_text_add_limit_error')};
            },

            getNbTextsOnActiveStep: function() {
                return $('.picture_container:visible .addTexts').length;
            },

            selectCustomizationStep: function(stepId, customizationStep) {
                this.$emit('selectCustomizationStep',stepId,customizationStep);
            },

            setSelectedCustomizationStep: function (customizationStep) {
                this.getSelectedCustomizationStep = customizationStep;
            },

            validateStep: function(stepId) {
                let _this = this;
                let nextStep = '';
                DataLayerTrigger.CustomCabas(5,'select custom logo', 'click', 'custom embroidery');

                $.each(_this.customizationSteps, function(index, value) {
                    if(value=='addText') {
                        if (_this.customizationSteps[(index+1)] != undefined) {
                            nextStep = _this.customizationSteps[(index+1)];
                        }
                    }
                });

                if (nextStep) {
                    _this.selectCustomizationStep(stepId, nextStep);
                }
                else {
                    this.configuratorStore.nextStep();
                }
            },
        }
    }

}());
